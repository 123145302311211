import { useEffect, useState } from 'react';
import {
  getOwnedTokens,
  getTokenURIs,
  mergeTokens,
  setContract,
} from '../helpers/web3Modal.service';
import { IDougMetadata, IDougInformation } from '../models/dougMetadata';
import {
  Center,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import Card from '../components/Card';
import Slider from '../components/Slider/Slider';
import questionImage from '../assets/images/question.png';
import { getContractAddress } from '../helpers/dougapi';
import { createMetaDataRequests } from '../helpers/utils';
import { RootStateOrAny, useSelector } from 'react-redux';

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const doug = useSelector((state: RootStateOrAny) => state.doug);
  const emptyMetadatas: IDougInformation[] = [];
  const emptySelectedDougs: boolean[] = [];
  const [metaDatas, setMetaDatas] = useState(emptyMetadatas);
  const [selectedDougs, setSelectedDougs] = useState(emptySelectedDougs);
  const [tokenA, setTokenA] = useState(-1);
  const [tokenB, setTokenB] = useState(-1);
  const [imageA, setImageA] = useState('');
  const [imageB, setImageB] = useState('');
  const [position, setPosition] = useState(0);
  const [isLoading, setIsloading] = useState(false);

  const tryGetOwnedTokens = async () => {
    try {
      const tokenIds = await getOwnedTokens();
      const uris = await getTokenURIs({
        tokenIds,
        dougRevealed: doug.dougRevealed,
      });

      const metaDataRequests = createMetaDataRequests(tokenIds, uris, []);

      const newMetaDatas = await (
        await Promise.all(metaDataRequests)
      ).filter((metaData) => metaData);
      setMetaDatas(newMetaDatas);
      const unselectedDougs = new Array(newMetaDatas.length).fill(false);
      setSelectedDougs(unselectedDougs);
    } catch (error) {
      if (error) {
        console.log(`Failed to get owned tokens: ${error}`);
      }
    }
  };

  useEffect(() => {
    tryGetOwnedTokens();
    //tryCurrentPrice();
  }, []);

  const onDougClicked = (index, tokenId: number, metaData: IDougMetadata) => {
    console.log(`Token Clicked: ${index}`);

    onClose();

    const newSelectedDougs = selectedDougs.slice();
    newSelectedDougs[index] = !newSelectedDougs[index];
    if (newSelectedDougs[index]) {
      if (tokenA < 0) {
        console.log(`setTokenA: ${tokenId}`);
        setTokenA(tokenId);
      } else if (tokenB < 0) {
        console.log(`setTokenB: ${tokenId}`);
        setTokenB(tokenId);
      }
    } else {
      if (tokenA === tokenId) {
        setTokenA(-1);
      } else if (tokenB === tokenId) {
        setTokenB(-1);
      }
    }
    if (position === 1) setImageA(metaData.image);
    if (position === 2) setImageB(metaData.image);

    setSelectedDougs(newSelectedDougs);
  };

  console.log(`Re-render`);

  useEffect(() => {}, [selectedDougs]);

  const onMerge = async () => {
    setIsloading(true);
    await mergeTokens(tokenA, tokenB);
    tryGetOwnedTokens();
    clearState();
  };

  const showMerge = tokenA >= 0 && tokenB >= 0;

  const openModal = (positionClicked: number) => {
    console.log('From box ', positionClicked);
    setPosition(positionClicked);
    onOpen();
  };

  const removeHandler = (slot) => {
    if (slot === 1) {
      setTokenA(-1);
      setImageA('');
    }

    if (slot === 2) {
      setTokenB(-1);
      setImageB('');
    }
  };

  const clearState = () => {
    setTokenA(-1);
    setImageA('');
    setTokenB(-1);
    setImageB('');
    setPosition(0);
    setIsloading(false);
  };

  return (
    <div>
      <Center h="50vh" color="white">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Card
            image={imageA}
            token={tokenA}
            onClick={openModal}
            slot={1}
            onRemove={removeHandler}
          />
          <div style={{ fontSize: '6vw', fontWeight: 'bold' }}>+</div>
          <Card
            image={imageB}
            token={tokenB}
            onClick={openModal}
            slot={2}
            onRemove={removeHandler}
          />
          <div style={{ fontSize: '6vw', fontWeight: 'bold' }}>=</div>
          <Card image={questionImage} isResult={true} />
        </div>
      </Center>
      {showMerge && (
        <Center>
          <Button
            fontSize="3vw"
            spinnerPlacement="start"
            isLoading={isLoading}
            background="#70591A"
            color="white"
            loadingText="Merging"
            height="auto"
            variant="outline"
            colorScheme="teal"
            onClick={onMerge}
          >
            UPGRADE!
          </Button>
        </Center>
      )}

      <Modal
        size="lg"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInRight"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent backgroundColor="gray.900">
          <ModalBody>
            <Center>
              <Slider
                items={metaDatas}
                selected={selectedDougs}
                onItemSelected={onDougClicked}
              />
            </Center>
          </ModalBody>
          <ModalFooter>
            <Center>
              <Button
                backgroundColor="gray.600"
                color="white"
                onClick={onClose}
              >
                Close
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>

    /* <div>Doug A</div>
                <div className="doug-token">{tokenA >= 0 ? tokenA : ""}</div>
                <div>Doug B</div>
                <div className="doug-token">{tokenB >= 0 ? tokenB : ""}</div>

                { }
                {showMerge && <div className="action-button" onClick={onMerge}>Merge</div>} */
    /* <Slider items={metaDatas} selected={selectedDougs} onItemSelected={onDougClicked} /> */
  );
};

export default Home;
