import { useEffect, useState } from 'react';

import { Center, Image } from '@chakra-ui/react';
import { mergeTokens } from '../helpers/web3Modal.service';
import Card from '../components/Card';
import Piggy from '../components/Piggy';
import '../assets/styles/fuse.css';
import dougs from '../assets/theme-2/images/dougs_2.png';
import black_doug from '../assets/theme-2/images/doug_silhouette_black.png';
import white_doug from '../assets/theme-2/images/doug_silhouette_white.png';
import left_arrow from '../assets/theme-2/images/left_arrow.png';
import right_arrow from '../assets/theme-2/images/right_arrow.png';
import left_arrow_mobile from '../assets/theme-2/images/left_arrow_mobile.png';
import right_arrow_mobile from '../assets/theme-2/images/right_arrow_mobile.png';
import connectors from '../assets/theme-2/images/connectors.png';
import connectors_inverted from '../assets/theme-2/images/connectors_inverted.png';
import callout from '../assets/theme-2/images/callout.png';
import { BigNumber } from 'ethers';
import {
  dougImageFromRankAndType,
  errorMessageToFriendlyMessage,
} from '../helpers/utils';
import Ranks from '../enums/Ranks';
import { IDougInformation } from '../models/dougMetadata';
import MetaMaskOnboarding from '@metamask/onboarding';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { connectWallet, setTx } from '../redux/wallet';
import {
  hideNotification,
  setNotification,
  showNotification,
  showNotificationCloseButton,
  showNotificationError,
} from '../redux/notification';

const Fuse = () => {
  const [account, setAccount] = useState<string>('');
  const state = useSelector((state: RootStateOrAny) => state);
  const wallet = useSelector((state: RootStateOrAny) => state.wallet);
  const dispatch = useDispatch();
  interface INotification {
    show: boolean;
    message: string;
    error: string;
    closeButton: boolean;
    callback?: any;
  }
  const emptyNotification: INotification = {
    show: false,
    message: '',
    error: '',
    closeButton: false,
  };

  const emptyMetadatas: IDougInformation[] = [];

  const [metaDatas, setMetaDatas] = useState(emptyMetadatas);
  const [tokenA, setTokenA] = useState(BigNumber.from('-1'));
  const [tokenB, setTokenB] = useState(BigNumber.from('-1'));
  const [imageA, setImageA] = useState(white_doug);
  const [imageB, setImageB] = useState(white_doug);
  const [rankA, setRankA] = useState(-1);
  const [rankB, setRankB] = useState(-1);
  const [typeA, setTypeA] = useState(-1);
  const [typeB, setTypeB] = useState(-1);
  const [balanceA, setBalanceA] = useState(-1);
  const [balanceB, setBalanceB] = useState(-1);
  const [rankMerged, setRankMerged] = useState('');
  const [imageMerged, setImageMerged] = useState(black_doug);
  const [dougTag, setDougTag] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [fuseCompleted, setFuseCompleted] = useState(false);
  const emptyToken = BigNumber.from('-1');

  const openModal = (positionClicked: number) => {
    console.log('From box ', positionClicked);
    const metaData_ = state.wallet.ownTokens;
    setMetaDatas(
      metaData_.map((doug) => ({
        ...doug,
        selected: !(doug.tokenId !== tokenA && doug.tokenId !== tokenB),
      }))
    );
    console.log(metaDatas);
  };

  const removeHandler = (slot) => {
    if (slot === 1) {
      setTokenA(emptyToken);
      setImageA(white_doug);
      setRankA(-1);
      setTypeA(-1);
    }

    if (slot === 2) {
      setTokenB(emptyToken);
      setImageB(white_doug);
      setRankB(-1);
      setTypeB(-1);
    }
  };

  const validateRangeMerge = (rankA_, typeA_, rankB_, typeB_) => {
    if (
      rankA_ === rankB_ &&
      typeA_ === typeB_ &&
      rankB_ !== -1 &&
      typeB_ !== -1
    ) {
      setRankMerged(Ranks[rankA_ + 1].title);
    } else {
      setRankMerged('');
      setImageMerged(black_doug);
    }
  };

  const onDougClicked = (slot, token, rank, type, image, balance) => {
    if (slot === 1) {
      setRankA(rank);
      setTypeA(type);
      setTokenA(token);
      setImageA(image);
      setBalanceA(balance);
      validateRangeMerge(rank, type, rankB, typeB);
    } else if (slot === 2) {
      setRankB(rank);
      setTypeB(type);
      setTokenB(token);
      setImageB(image);
      setBalanceB(balance);
      validateRangeMerge(rankA, typeA, rank, type);
    }
  };

  const onMerge = async () => {
    try {
      if (tokenA !== emptyToken && tokenB > emptyToken) {
        if (typeA === typeB && rankA === rankB) {
          dispatch(showNotification('FUSION PENDING'));
          await mergeTokens(tokenA, tokenB);
          dispatch(setTx(JSON.parse(localStorage.getItem('tx0'))));
          setFuseCompleted(true);
          // removeHandler(1);
          // removeHandler(2);
          setImageMerged(dougImageFromRankAndType(typeA, rankA + 1, imageA));
          let result = await loadDougTagImage(
            rankA + 1 + '_' + Ranks[rankA].title
          );
          setDougTag(result);
        } else {
          let message = '';
          if (rankA !== rankB)
            message = 'Dougs must be the same Rank to Rank up';
          if (typeA !== typeB)
            message = 'Dougs must be the same type to Rank up';
          dispatch(
            showNotificationError({ message: 'FUSION FAILED', error: message })
          );
        }
      }
    } catch (error) {
      dispatch(
        showNotificationError({
          message: 'FUSION FAILED',
          error: errorMessageToFriendlyMessage(error),
        })
      );
    }
  };

  const loadDougTagImage = async (imageName: string) => {
    return await import(`../assets/theme-2/images/${imageName}.jpg`).then(
      (image) => {
        return image.default;
      }
    );
  };

  const connectHandler = async () => {
    try {
      const onboarding = new MetaMaskOnboarding();
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        if (wallet.account === null) {
          dispatch(connectWallet());
        }
      } else {
        onboarding.startOnboarding();
      }
    } catch (error) {
      if (error) {
        window.open('https://metamask.io/download/', '_blank');
      }
    }
  };

  useEffect(() => {
    if (wallet.transaction.hash === '' && fuseCompleted) {
      dispatch(
        setNotification({
          show: true,
          message: 'FUSION COMPLETED',
          error: '',
          closeButton: true,
          callback: startAnimation,
        })
      );
      setFuseCompleted(false);
    }
  }, [wallet.transaction]);

  const startAnimation = () => {
    setAnimate(true);
  };
  return (
    <div className="MainBg">
      <div className="fuse-container">
        <div className="mintbg2 mint-bg-pad-25">
          <div className="fuse-steps">
            <div className="fuse-step fuse-step-border">
              <div className="fuse-step-body">
                <span className="fuse-number">1.</span>
                <div className="fuse-description">
                  Collect two identical Dougs then <br />
                  <div
                    className="btn-connect-your-wallet"
                    onClick={connectHandler}
                  >
                    <label className="text-white">
                      {account ? 'CONNECTED' : 'CONNECT WALLET'}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="fuse-step fuse-step-border no-step-border-mobile">
              <div className="fuse-step-body">
                <span className="fuse-number">2.</span>
                <p className="fuse-description">
                Select two identical Doug Ordinals to add to each box
                </p>
              </div>
            </div>

            <div className="fuse-step fuse-step-border">
              <div className="fuse-step-body">
                <span className="fuse-number">3.</span>
                <p className="fuse-description">
                Click the fuse button to inscribe your ordinals and evolve
                </p>
              </div>
            </div>

            <div className="fuse-step">
              <div className="fuse-step-body">
                <span className="fuse-number">4.</span>
                <p className="fuse-description">
                One Doug is promoted, the other commemorated into a Doug Tag
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mintbg2">
          <div className="fuse-avatars">
            <Center color="white">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Card
                  image={imageA}
                  token={tokenA}
                  onClick={openModal}
                  slot={1}
                  onRemove={removeHandler}
                  onItemSelected={onDougClicked}
                  rank={rankA}
                  type={typeA}
                  metaDatas={metaDatas}
                  animate={animate}
                  setAnimate={setAnimate}
                  dougTag={dougTag}
                  setDougTag={setDougTag}
                  arrowMobileImage={left_arrow_mobile}
                />
                <Image
                  className="fuse-arrow"
                  src={left_arrow}
                  alt="left-arrow"
                />
                <Card
                  image={imageMerged}
                  isResult={true}
                  onMerge={onMerge}
                  rankMerged={rankMerged}
                  dougMerged={imageMerged}
                  animate={animate}
                  setAnimate={setAnimate}
                  dougA={imageA}
                  dougB={imageB}
                  whiteDoug={white_doug}
                  dougTag={dougTag}
                  setDougTag={setDougTag}
                  onRemove={removeHandler}
                />
                <Image
                  className="fuse-arrow"
                  src={right_arrow}
                  alt="right-arrow"
                />
                <Card
                  image={imageB}
                  token={tokenB}
                  onClick={openModal}
                  slot={2}
                  onRemove={removeHandler}
                  onItemSelected={onDougClicked}
                  rank={rankB}
                  type={typeB}
                  metaDatas={metaDatas}
                  animate={animate}
                  setAnimate={setAnimate}
                  dougTag={dougTag}
                  setDougTag={setDougTag}
                  arrowMobileImage={right_arrow_mobile}
                />
              </div>
              <div className="fuse-callout-container">
                <Image
                  className="fuse-connector-inverted-image"
                  src={connectors_inverted}
                />
                <Image className="fuse-connector-image" src={connectors} />
                <Image
                  className="fuse-callout-image"
                  src={callout}
                  alt="callout"
                />
                <p className="callout-text">
                  <label className="callout-text-1">CAUTION!</label> <br />
                  <label className="callout-text-2">
                    RANKING UP DOUG REQUIRES STRATEGIC SACRIFICE AND WILL CHANGE YOUR ORDINALS PERMANENTLY
                  </label>
                </p>

              </div>
            </Center>
          </div>
        </div>

        <div className="fuse-footer">
          <div className="fuse-footer-div-image">
            <img src={dougs} alt="dougs" />
          </div>
          <div className="fuse-footer-div-text">
            <div className="fuse-text-footer">
              <span className="fuse-text-footer-1">UNLEASH</span>
              <br />
              <span className="fuse-text-footer-2">THE DOUGS OF WAR </span>
              <p className="fuse-text-footer-3 bold">
                From Ghengis to Alexander: The leaders of history know that
                strategy and sacrifice are often insperable. Are you ready to
                sacrifice two Dougs for one new, rarer and more valuable Doug?
                To rise up the ranks? Have you got what it takes to FUSE and
                create a more powerful Doug?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fuse;
