import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../index';
import { login, logout } from '../actions/auth';
import {
  connect,
  getOwnedSBTDougInformations,
  makeTransaction,
} from '../../helpers/web3Modal.service';
import { IConnectionInfo, IWallet } from '../../models/wallet';

import {
  getOwnedDougInformations,
  setDougReady,
  updateOwnSBTTokens,
  updateOwnTokens,
} from './doug';
import { ethers } from 'ethers';
import { ITransaction } from '../../models/transaction';
import { TransactionEnum } from '../../enums/transaction';
import {
  hideNotification,
  showNotification,
  showNotificationCloseButton,
  showNotificationError,
} from '../notification';
import { IDougInformation } from '../../models/dougMetadata';
import { ISoulboundInformation } from '../../models/soulboundMetadata';

export const setTx = createAction<ITransaction>('wallet/setTx');
export const setExpireDate = createAction<void>('wallet/setExpireDate');
export const setOwntokens = createAction<IDougInformation[]>(
  'wallet/setOwntokens'
);
export const setOwnSBTtokens = createAction<ISoulboundInformation[]>(
  'wallet/setOwnSBTtokens'
);

export const listenTransactions = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('wallet/listenTransactions', async (_, { dispatch, getState }) => {
  const connectionInfo: IConnectionInfo = (await connect()) as IConnectionInfo;
  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const {
    wallet: { transaction },
  } = getState();
  let message_end;
  let message_error;
  let updateInfo: any = () => {
    console.log('do nothing');
  };
  try {
    provider.once(transaction.hash, async (tx) => {
      switch (transaction.type) {
        case TransactionEnum.MINT: {
          message_end = 'MINT SUCCESS!';
          message_error = 'MINT FAILED';
          updateInfo = () => {
            dispatch(updateOwnTokens());
            console.log('updating tokens');
          };
          break;
        }
        case TransactionEnum.COLLECT_ALL:
          message_end = 'COLLECT SUCCESS!';
          message_error = 'COLLECT FAILED';
          updateInfo = () => {
            dispatch(updateOwnTokens());
            console.log('updating tokens');
          };
          break;
        case TransactionEnum.MERGE:
          message_end = 'FUSION SUCCESS';
          message_error = 'FUSION FAILED';
          updateInfo = () => {
            dispatch(updateOwnTokens());
            console.log('updating tokens');
          };
          break;
        case TransactionEnum.MINT_SOULBOUND: {
          message_end = 'SBT MINT SUCCESS!';
          message_error = 'SBT MINT FAILED';
          updateInfo = () => {
            dispatch(updateOwnSBTTokens());
            console.log('updating sbt tokens');
          };
          break;
        }
        case TransactionEnum.WHITELIST: {
          message_end = 'WHITELIST SUCCESS!';
          message_error = 'WHITELISTED FAILED';
          break;
        }
        case TransactionEnum.REDEEM_MANY: {
          message_end = 'REDEEM SUCCESS!';
          message_error = 'REDEEM FAILED!';
          updateInfo = () => {
            dispatch(updateOwnTokens());
            console.log('update doug tokens');
            dispatch(updateOwnSBTTokens());
            console.log('update sbt tokens');
          };
          break;
        }
      }
      await makeTransaction(transaction.hash, () => {
        dispatch(setTx({ hash: '', type: '', confirmations: '' }));
        localStorage.removeItem('tx0');
        updateInfo();
        dispatch(showNotificationCloseButton(message_end));
      });
    });
  } catch (error) {
    console.log(`error ${error}`);
    dispatch(showNotificationError({ message: message_error, error }));
  }
});

export const connectWallet = createAsyncThunk<
  string[],
  void,
  { state: RootState; dispatch: AppDispatch }
>('wallet/connectWallet', async (_, { dispatch }) => {
  // const unlocked: boolean = await window.ethereum._metamask.isUnlocked();
  // if (!unlocked) {
  //   dispatch(logout());
  //   throw new Error('Please unlock your metamask wallet before proceeding');
  // }
  dispatch(showNotification('CONNECTING WALLET'));
  const connectionInfo = (await connect().then((response) => {
    return response;
  })) as IConnectionInfo;
  if (connectionInfo.wallet != null) {
    const dougInformation = (await dispatch(getOwnedDougInformations()).then(
      unwrapResult
    )) as IDougInformation[];
    const SBTInformation =
      (await getOwnedSBTDougInformations()) as ISoulboundInformation[];
    const wallet: IWallet = {
      account: connectionInfo.wallet,
      ownTokens: dougInformation,
      ownSBTTokens: SBTInformation,
    };
    dispatch(login(wallet));
    dispatch(hideNotification());
  } else {
    dispatch(logout());
  }

  return [connectionInfo.wallet];
});
