import React from 'react';
import '../assets/styles/DougVisualizer.css';
import { openseaLink } from '../helpers/visualizer.utils';
import type { Doug, DougDay } from '../models/Doug';

interface DougBarProps {
  doug: Doug;
  timePerUnit: number;
}

function DougBar({ doug, timePerUnit }: DougBarProps) {
  const dougClick = (dougToken: number) => {
    return () => {
      window.open(openseaLink(dougToken));
    };
  };

  const toolTip = (name: string, r: number, token: number) => {
    return `${name} - Rank ${r}\n${openseaLink(token)}`;
  };

  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  };

  const topRank = () => {
    const dimensions = getWindowDimensions();
    if (dimensions.width <= 600) return 55;
    else return 75;
  };

  return (
    <div
      className="doug"
      style={{
        transition: `top ${timePerUnit}ms`,
        top: doug.next_rank * topRank() + 'px',
      }}
    >
      <div className="doug-name">{doug.name}</div>
      <div style={{ display: 'flex' }}>
        <div>
          <img className="doug-image" src={doug.image} alt="doug" />
        </div>
        <div className="doug-ranks">
          {doug.ranks.map((r, idx) => (
            <div
              onClick={dougClick(doug.tokens[idx])}
              key={idx}
              title={toolTip(doug.name, r, doug.tokens[idx])}
              className={`rank rank_${r}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface DougVisualizerProps {
  dougs: DougDay;
  timePerUnit: number;
}

function DougVisualizerRace({ dougs, timePerUnit }: DougVisualizerProps) {
  return (
    <div className="doug-visualizer">
      {dougs.map((d, idx) => (
        <div key={`rank_div_${idx}`}>
          <div key={`rank_${idx}`} className="visualizer-rank-number">
            <label key={`rank_label_${idx}`}>{idx + 1}</label>
          </div>
          <DougBar key={idx} doug={d} timePerUnit={timePerUnit} />
        </div>
      ))}
    </div>
  );
}

export default DougVisualizerRace;
