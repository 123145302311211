import { IDougInformation } from '../models/dougMetadata';
import { IPremintOptions } from '../models/Mint';

const host = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

const api = `api/v1/`;

export const getContractAddress = async () => {
  const method = `contract-address`;
  const url = `${host}${api}${method}`;

  console.log(`getContractAddress ${url}`);
  const response = await fetch(url);
  const responseJson = await response.json();
  console.log(`Contract Address: ${JSON.stringify(responseJson)}`);

  return responseJson;
};

export const preMint = async (premintOptions: IPremintOptions) => {
  const method = `pre-mint`;
  const url = `${host}${api}${method}`;
  const bodyParams = JSON.stringify(premintOptions);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: bodyParams,
  });
  const preMintResponse = await response.json();
  console.log(`Pre Mint: ${JSON.stringify(preMintResponse)}`);
  return {
    nonce: preMintResponse.nonce,
    signature: preMintResponse.signature,
    error: preMintResponse.error,
  };
};

export const orderDougs = (dougs: IDougInformation[]) => {
  const SortByTypeRank = (items) => {
    console.log(items);
    items.sort(function (doug1, doug2) {
      const type1 = doug1.metadata.doug.type;
      const type2 = doug2.metadata.doug.type;
      const rank1 = doug1.metadata.doug.rank;
      const rank2 = doug2.metadata.doug.rank;

      if (rank1 > rank2) return -1;
      if (rank1 < rank2) return 1;

      if (type1 < type2) return -1;
      if (type1 > type2) return 1;
    });
  };
  SortByTypeRank(dougs);

  const groupDougs = dougs.map((doug) => {
    const order_dougs = dougs
      .map((doug2) => {
        if (doug !== doug2) {
          if (doug.metadata.doug.type === doug2.metadata.doug.type)
            return doug2;
        } else return doug2;
      })
      .filter((element) => {
        return element !== undefined;
      });
    return order_dougs;
  });
  const filteredDougs = [].concat
    .apply([], groupDougs)
    .filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });
  return filteredDougs;
};
