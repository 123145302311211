import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { IDougInformation } from '../../models/dougMetadata';
import { AppDispatch, RootState } from '..';
import {
  getDougRevealed,
  getOwnedSBTDougInformations,
  getOwnedTokens,
  getTokenBalances,
  getTokenURIs,
} from '../../helpers/web3Modal.service';
import { createMetaDataRequests } from '../../helpers/utils';
import { orderDougs } from '../../helpers/dougapi';
import { IContracts } from '../../models/wallet';
import { setOwnSBTtokens, setOwntokens } from './wallet';
import { ISoulboundInformation } from '../../models/soulboundMetadata';

export const setDougReady = createAction<boolean>('doug/setDougReady');
export const setContracts = createAction<IContracts>('doug/setContracts');
export const setDougRevealed = createAction<boolean>('doug/setDougRevealed');
export const setPrice = createAction<string>('doug/setPrice');
export const setPromoCode = createAction<string>('doug/setPromoCode');
export const setRedeemStart = createAction<string>('doug/setRedeemStart');
export const setStartedAt = createAction<string>('doug/setStartedAt');
export const setPromoStartedAt = createAction<string>('doug/setPromoStartedAt');

export const getOwnedDougInformations = createAsyncThunk<
  IDougInformation[],
  void,
  { state: RootState; dispatch: AppDispatch }
>('doug/getOwnedDougInformations', async (_, { getState, dispatch }) => {
  const {
    doug: { dougRevealed },
  } = getState();
  if (!dougRevealed) {
    const _dougRevealed = await getDougRevealed();
    dispatch(setDougRevealed(_dougRevealed));
    console.log(`******* DOUG Revealed: ${dougRevealed}`);
  }
  console.log(`Calling Get Owned Tokes`);
  const tokenIds = await getOwnedTokens();
  console.log(`Get Owned Tokens: ${tokenIds}`);
  const uris = await getTokenURIs({
    tokenIds,

    dougRevealed,
  });
  const balances = await getTokenBalances(tokenIds);
  const metaDataRequests = createMetaDataRequests(tokenIds, uris, balances);
  const newMetaDatas = await (
    await Promise.all(metaDataRequests)
  ).filter((metaData) => metaData);
  const md = newMetaDatas.map((metadata) => {
    metadata.balance = metadata.balance.toNumber();
    return metadata;
  });
  if (!dougRevealed) return md;
  return orderDougs(md);
});

export const updateOwnTokens = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('doug/updateOwnTokens', async (_, { dispatch }) => {
  dispatch(setDougReady(false));
  const dougInformation = (await dispatch(getOwnedDougInformations()).then(
    unwrapResult
  )) as IDougInformation[];
  dispatch(setOwntokens(dougInformation));
  dispatch(setDougReady(true));
});

export const updateOwnSBTTokens = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('doug/updateOwnSBTTokens', async (_, { dispatch }) => {
  dispatch(setDougReady(false));
  const SBTInformation =
    (await getOwnedSBTDougInformations()) as ISoulboundInformation[];
  dispatch(setOwnSBTtokens(SBTInformation));
  dispatch(setDougReady(true));
});
