import { configureStore } from '@reduxjs/toolkit';
import wallet from './wallet';
import doug from './doug';
import header from './header';
import notification from './notification';

import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: { wallet, doug, header, notification },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const configureMockStore = (preloadedState: Partial<RootState> = {}) =>
  configureStore({
    reducer: { wallet },
    preloadedState,
  });

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
