import Home from '../pages/Home';
import Mint from '../pages/Mint';
import Fuse from '../pages/Fuse';
import Soulbound from '../pages/Soulbound';
import Whitelist from '../pages/whitelist';
import Visualizer from '../pages/Visualizer';
import Bank from '../pages/Bank';
import HelperPage from '../pages/HelperPage';
import Redeem from '../pages/redeem';

interface Route {
  to: string;
  path: string;
  Component: () => JSX.Element;
  name: string;
  title: string;
  subtitle: string;
  subtitle_orange: string;
  subtitle_complement: string;
  subtitleTop: boolean;
  show: boolean;
  mobile_title_1?: string;
  mobile_title_2?: string;
  mobile_title_3?: string;
}

const productionRoutes: Route[] = [
  {
    to: '/',
    path: '/',
    Component: Fuse,
    name: 'Fuse',
    title: 'RANK UP DOUG',
    subtitle: 'BY COMBINING TWO IDENTICAL DOUGS',
    subtitle_complement: '',
    subtitle_orange: '',
    subtitleTop: false,
    show: false,
    mobile_title_1: 'RANK',
    mobile_title_2: 'UP',
    mobile_title_3: 'YOUR DOUG',
  },
  {
    to: '/white-paper',
    path: '/white-paper',
    Component: Home,
    name: 'White Paper',
    title: 'COMMANDER DOUG',
    subtitle: 'COMMANDER DOUG',
    subtitle_orange: '',
    subtitle_complement: '',
    subtitleTop: false,
    show: false,
  },
  {
    to: '/mint',
    path: '/mint',
    Component: Mint,
    name: 'Mint',
    title: 'DOUG MINT',
    subtitle: '',
    subtitle_orange: '',
    subtitle_complement: '',
    subtitleTop: false,
    show: false,
  },
  {
    to: '/fuse',
    path: '/fuse',
    Component: Fuse,
    name: 'Fuse',
    title: 'RANK UP DOUG',
    subtitle: 'BY COMBINING TWO IDENTICAL DOUGS',
    subtitle_complement: '',
    subtitle_orange: '',
    subtitleTop: false,
    show: true,
    mobile_title_1: 'RANK',
    mobile_title_2: 'UP',
    mobile_title_3: 'YOUR DOUG',
  },
  {
    to: '/leaderboard',
    path: '/leaderboard',
    Component: Visualizer,
    name: 'Leaderboard',
    title: 'LEADERBOARD',
    subtitle: 'SEE WHO IS MAKING ALL THE MONEY',
    subtitle_orange: '',
    subtitle_complement: '',
    subtitleTop: true,
    show: true,
  },
];

const testRoutes: Route[] = [
  {
    to: '/helper',
    path: '/helper',
    Component: HelperPage,
    name: 'Helper Page',
    title: 'HELPER PAGE',
    subtitle: 'EXTRA INFO',
    subtitle_orange: 'SEE MORE DATA',
    subtitle_complement: '',
    subtitleTop: false,
    show: true,
  },
];

let userRoutes = productionRoutes;
if (process.env.REACT_APP_PUBLIC_ENV == 'development') {
  userRoutes = userRoutes.concat(testRoutes);
}

export const routes: Route[] = userRoutes;
