import { useEffect, useState } from 'react';
import { AuctionState } from '../enums/AuctionState';
import { makeNiceCountdown, replaceZero } from '../helpers/utils';

interface MintCountDownProps {
  auctionState: AuctionState | null;
  startTime: number;
  showTextDate: boolean;
}

const MintCountDown = ({
  auctionState,
  startTime,
  showTextDate,
}: MintCountDownProps) => {
  const currentDate = new Date();
  const currentTimeSeconds = Math.floor(currentDate.getTime() / 1000);
  const secondsFromStart = currentTimeSeconds - startTime;
  const halfHour = 30 * 60;
  const halfHours = Math.floor(secondsFromStart / halfHour);
  const secondsLeft = halfHour - (secondsFromStart - halfHours * halfHour);
  const [countDown, setCountDown] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTimeSeconds < startTime) {
        setCountDown(Math.abs(startTime - currentTimeSeconds));
      } else {
        setCountDown(secondsLeft);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  const ResultCountDown = makeNiceCountdown(countDown);

  // if (auctionState != AuctionState.DutchAuction) {
  //   return <>00:00</>;
  // }

  return (
    <>
      {showTextDate ? (
        <>
          {startTime > 0 ? (
            <>
              {ResultCountDown[0] > 0 &&
                replaceZero(ResultCountDown[0].toString().padStart(2, '0')) +
                  ' DAYS '}
              {ResultCountDown[1] > 0 &&
                replaceZero(ResultCountDown[1].toString().padStart(2, '0')) +
                  ' HRS '}
              {replaceZero(ResultCountDown[2].toString().padStart(2, '0')) +
                ' MINS'}
            </>
          ) : (
            <>O DAYS O HRS O MINS</>
          )}
        </>
      ) : (
        <>
          {startTime > 0 ? (
            <>
              {ResultCountDown[0] > 0 &&
                ResultCountDown[0].toString().padStart(2, '0') + ' DAYS '}
              {ResultCountDown[1] > 0 &&
                ResultCountDown[1].toString().padStart(2, '0') + ':'}
              {ResultCountDown[2].toString().padStart(2, '0')}:
              {ResultCountDown[3].toString().padStart(2, '0')}
            </>
          ) : (
            <>59:59</>
          )}
        </>
      )}
    </>
  );
};

export default MintCountDown;
