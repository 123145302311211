import { Button, Image } from '@chakra-ui/react';
import dog_tag_3 from '../assets/theme-2/images/dog_tag_3.png';
import dog_tag_3_2 from '../assets/theme-2/images/dog_tag_3_2.png';

const Minting = ({
  qty,
  price,
  coin,
  image,
  enable,
  handleMintClick,
  messageOptions,
}) => {
  let classNameContainer = enable
    ? 'mint-element-container'
    : 'mint-element-container mint-disabled';
  const _price_3 = isNaN(parseFloat(price)) ? price : price.toFixed(3);
  const _price_7 = isNaN(parseFloat(price)) ? price : price.toFixed(7);
  return (
    <div className={classNameContainer}>
      <div className="mint-text-5 mint-text-mobile-hide ">GET</div>
      <div className="mint-text-5 mint-text-mobile-show ">MINT</div>
      <div className="mint-text-6 ">{qty}</div>
      <div>
        <Image className="mint-doug-shadow" src={image} />
      </div>
      <div className="mint-text-7">TOTAL COST:</div>
      <div className="mint-text-8">
        <span style={{ position: 'relative' }}>
          {_price_3} {coin}
          <span className="span-title">{_price_7}</span>
        </span>
      </div>
      <Button
        className={
          'mint-btn ' +
          (messageOptions.pulseMintButton ? 'btn-pulse' : 'btn-disabled')
        }
        onClick={handleMintClick}
      >
        <Image
          position="absolute"
          src={messageOptions.pulseMintButton ? dog_tag_3 : dog_tag_3_2}
        />
        <label className="mint-text-mobile-hide">MINT</label>
        <label className="mint-text-mobile-show">DO IT</label>
      </Button>
    </div>
  );
};

export default Minting;
