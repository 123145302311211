import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import MetaMaskOnboarding from '@metamask/onboarding';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { connectWallet } from '../redux/wallet';

const ConnectButtonMobile = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((state: RootStateOrAny) => state.wallet);

  const getAccount = async () => {
    try {
      return (
        (await window.ethereum.request({ method: 'eth_accounts' })) || false
      );
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const connectHandler = async () => {
    try {
      const onboarding = new MetaMaskOnboarding();

      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        console.log('gio connect connectbuttonmobile');

        if (wallet.account === null) {
          dispatch(connectWallet());
        }
      } else {
        onboarding.startOnboarding();
      }
    } catch (error) {
      if (error) {
        window.open('https://metamask.io/download/', '_blank');
      }
    }
  };
  return (
    <Button className="btn-connect-mobile" onClick={() => connectHandler()}>
      <label>{wallet.account ? 'CONNECTED' : 'CONNECT WALLET'}</label>
    </Button>
  );
};

export default ConnectButtonMobile;
