import { createAction } from '@reduxjs/toolkit';
import { INotificationState } from '../notification';

export const setNotification = createAction<INotificationState>(
  'notification/setNotification'
);

export const showNotification = createAction<string>(
  'notification/showNotification'
);

export const showNotificationError = createAction<{
  message: string;
  error: string;
}>('notification/showNotificationError');

export const showNotificationCloseButton = createAction<string>(
  'notification/showNotificationCloseButton'
);

export const hideNotification = createAction<void>(
  'notification/hideNotification'
);
