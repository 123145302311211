import { createSlice } from '@reduxjs/toolkit';
import * as ac from './actions/header';

export interface IHeaderState {
  name: string;
  subtitle: string;
  subtitleOrange: string;
  subtitleComplement: string;
}

const initialState: IHeaderState = {
  name: '',
  subtitle: '',
  subtitleOrange: '',
  subtitleComplement: '',
};

const dougSlice = createSlice({
  name: 'doug',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ac.setSubtitle, (state, action) => {
      state = action.payload;
    });
  },
});

export * from '../redux/actions/header';
export default dougSlice.reducer;
