export type DougToken = {
  id: number;
  type: number;
  rank: number;
};

export type HistoryStep = {
  dougs: DougToken[];
  leaderboard: number[];
};

export class HistoryDecoder {
  private readonly reader: ArrayReader;

  private leaderboard: number[];

  private tokensById: { [k: number]: DougToken };

  private mintCounter = 0;

  private mergeCounter = 12700;

  private includeMints = false;

  constructor(buffer: Buffer, includeMints = false) {
    this.reader = new ArrayReader(new Uint8Array(buffer));
    this.leaderboard = new Array<number>(100);
    for (let i = 0; i < this.leaderboard.length; i++) {
      this.leaderboard[i] = i;
    }
    this.tokensById = {};
    this.includeMints = includeMints;
  }

  *decodeSteps(): Generator<HistoryStep, void, void> {
    while (!this.reader.eof()) {
      const opType = this.reader.readByte();
      const opFlag = (opType & 128) >> 7;
      const type = opType & 127;

      if (opFlag === 1) {
        // merge
        const id = ++this.mergeCounter;
        this.parseMerge(type, id);
        yield this.state();
      } else {
        // mint
        const id = ++this.mintCounter;
        this.tokensById[id] = {
          id: id,
          type,
          rank: 1,
        };
        if (this.includeMints) {
          yield this.state();
        }
      }
    }
  }

  state() {
    return {
      dougs: Object.values(this.tokensById),
      leaderboard: this.leaderboard,
    };
  }

  parseMerge(type: number, id: number) {
    const idA = this.reader.readInt16();
    const idB = this.reader.readInt16();
    const ancestA = this.tokensById[idA];
    const ancestB = this.tokensById[idB];
    if (
      !ancestA ||
      !ancestB ||
      ancestA.type != ancestB.type ||
      ancestA.rank != ancestB.rank
    ) {
      throw new Error(
        `${id} parent's mismatch: ${JSON.stringify(ancestA)}` +
          ` / ${JSON.stringify(ancestB)}`
      );
    }
    const rank = ancestA.rank + 1;
    delete this.tokensById[idA];
    delete this.tokensById[idB];
    this.tokensById[id] = {
      id,
      type,
      rank,
    };
    this.parseLeaderboard();
  }

  parseLeaderboard() {
    const deltaCount = this.reader.readByte();
    if (deltaCount === 0xff) {
      // full update
      this.leaderboard = this.reader.readBytes(100);
    } else if (deltaCount > 0) {
      const deltas = this.reader.readBytes(deltaCount * 2);
      for (let i = 0; i < deltas.length; i += 2) {
        const ix = deltas[i];
        const value = deltas[i + 1];
        this.leaderboard[ix] = value;
      }
    }
  }
}

class ArrayReader {
  private next: number = 0;

  constructor(private readonly data: Uint8Array) {}

  readByte(): number {
    this.assertLength(1);
    const val = this.data[this.next];
    this.next++;
    return val;
  }

  readBytes(length: number): number[] {
    this.assertLength(length);
    const chunk = this.data.slice(this.next, this.next + length);
    this.next += length;
    return Array.from(chunk.values());
  }

  readInt16(): number {
    this.assertLength(2);
    const lsb = this.data[this.next];
    this.next++;
    const msb = this.data[this.next];
    this.next++;
    return (msb << 8) | lsb;
  }

  assertLength(length: number) {
    if (this.data.length - this.next < length)
      throw new Error(
        `can't read ${length} byte(s) from ${this.data.length} bytes at offset ${this.next}`
      );
  }

  eof(): boolean {
    return this.next >= this.data.length - 1;
  }
}
