import loadingGif from '../assets/images/loading.gif';

const Loading = ({ show = false }) => {
  let classLoading = 'loading-hide';
  if (show) {
    classLoading = 'loading';
  }
  return (
    <div className={classLoading}>
      <img src={loadingGif} alt="Loading..." />
    </div>
  );
};

export default Loading;
