import { BigNumber, ethers } from 'ethers';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import DougABI from '../contracts/Doug.json';
import DougBankABI from '../contracts/DougBank.json';
import SoulBound from '../contracts/DougSoul.json';
import SoulRedeemer from '../contracts/SoulRedeemer.json';
import { getContractAddress, orderDougs } from './dougapi';
import {
  createMetaDataRequests,
  createMetaDataSBTRequests,
  isRedeem,
} from './utils';

const GoerliJsonRpcProvider =
  'https://goerli.infura.io/v3/2cfa4518f3134429a206aa2b00bd3d24';
const MainnetJsonRpcProvider =
  'https://mainnet.infura.io/v3/2cfa4518f3134429a206aa2b00bd3d24';

let chainHost = 'goerli';
let CurrentJsonRpcProvider = GoerliJsonRpcProvider;

if (process.env.REACT_APP_PUBLIC_CHAIN_ID == '1') {
  chainHost = 'mainnet';
  CurrentJsonRpcProvider = MainnetJsonRpcProvider;
} else {
  chainHost = 'goerli';
  CurrentJsonRpcProvider = GoerliJsonRpcProvider;
}

const provider = new ethers.providers.JsonRpcProvider(CurrentJsonRpcProvider);

const web3Modal = new Web3Modal({
  network: chainHost,
});

export var dougContract = localStorage.getItem('dougContract') ?? '';
export var bankContract = localStorage.getItem('bankContract') ?? '';
export var soulboundContract = localStorage.getItem('soulboundContract') ?? '';
export var soulRedeemerContract =
  localStorage.getItem('soulRedeemerContract') ?? '';

var connectionInfo = {
  provider: null,
  wallet: null,
};

export const setContract = (contract) => {
  dougContract = contract.doug;
  bankContract = contract.bank;
  soulboundContract = contract.soul;
  soulRedeemerContract = contract.redeemer;
  console.log(`Using doug contract: ${dougContract}`);
  console.log(`Using bank contract: ${bankContract}`);
  console.log(`Using soulbound contract: ${soulboundContract}`);
  console.log(`Using redeem contract: ${soulRedeemerContract}`);
};

// Used only for running a blockchain locally
export const init = async () => {
  //if (dougContract != null) return;
  const contractResponse = await getContractAddress();
  dougContract = contractResponse.address;
};

export const connect = async () => {
  try {
    connectionInfo.provider = await web3Modal.connect();
    const accounts = await getAccounts(connectionInfo.provider);
    connectionInfo.wallet = accounts[0];
    return connectionInfo;
  } catch (error) {
    return error;
  }
};

export const isConnected = () => connectionInfo.wallet != null;

export const wallet = () => connectionInfo.wallet;

let dougRevealed = false;

export const IsDougRevealed = () => dougRevealed;

export const GetMinPrice = async () => await getFinalPrice();

export const getAccounts = async (provider: any) => {
  const web3 = new Web3(connectionInfo.provider);
  const accounts = await web3.eth.getAccounts();

  return accounts;
};

export const getCurrentPrice = async (useCurrentPrice = true) => {
  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);
    const currentPrice: BigNumber = useCurrentPrice
      ? await dougGame.currentPrice()
      : await dougGame.getInvitePrice();
    return currentPrice;
  } catch (error) {
    console.warn(`Failed current price: ${error}`);
    throw error;
  }
};

export const getStartedAt = async () => {
  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);
    const startedAt: BigNumber = await dougGame.startedAt();
    const d = new Date(startedAt.toNumber() * 1000).toLocaleString();
    console.log(`DATE*****: ${d}`);
    return startedAt;
  } catch (error) {
    console.warn(`Failed startedAt: ${error}`);
    throw error;
  }
};

export const getFinalPrice = async () => {
  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);
    const finalPrice: BigNumber = await dougGame.finalPrice();
    return finalPrice;
  } catch (error) {
    console.warn(`Failed finalPrice: ${error}`);
    throw error;
  }
};

export const getDougRevealed = async () => {
  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);
    const revealed: boolean = await dougGame.revealed();
    return revealed;
  } catch (error) {
    console.warn(`Failed startedAt: ${error}`);
    throw error;
  }
};

export const getPromoStartedAt = async () => {
  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);
    console.log('gio getInviteStartTime');
    const startedAt: BigNumber = await dougGame.getInviteStartTime();
    return startedAt;
  } catch (error) {
    console.warn(`Failed getInviteStartTime: ${error}`);
    throw error;
  }
};

export const getDougsRemaining = async () => {
  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);
    const remaining: BigNumber = await dougGame.dougsRemaining();
    return remaining;
  } catch (error) {
    console.warn(`Failed remaining: ${error}`);
    throw error;
  }
};

export const getOwnedTokens = async () => {
  const dougGame = new ethers.Contract(dougContract, DougABI, provider);

  const dougs = new Set<number>();
  console.log('gio getOwnedTokens mints');
  const mints = await dougGame.queryFilter(
    dougGame.filters.Transfer(null, connectionInfo.wallet)
  );
  console.log('gio getOwnedTokens burns');
  const burns = await dougGame.queryFilter(
    dougGame.filters.Transfer(connectionInfo.wallet, null)
  );

  // sort by block no
  const events = [...mints, ...burns].sort(
    (a, b) => a.blockNumber - b.blockNumber
  );
  for (const evt of events) {
    const id = evt.args.tokenId.toNumber();
    const from = evt.args.from.toString(16);

    if (from === connectionInfo.wallet) {
      dougs.delete(id);
    } else {
      dougs.add(id);
    }
  }
  const ownedTokens = Array.from(dougs);

  ownedTokens
    .sort((tokenA, tokenB) => {
      return tokenA > tokenB ? 1 : -1;
    })
    .forEach((token) => {
      // console.log(`Owned Token: #${token}`);
    });

  return ownedTokens;
};

///
/// getOwnedTokenMetadatas
/// Gathers owned tokens by this wallet, parses the token data (rank, doug type),
/// rand etrieves the metadata associated with the token
export const getOwnedDougInformations = async () => {
  if (!dougRevealed) {
    dougRevealed = await getDougRevealed();
    console.log(`******* DOUG Revealed: ${dougRevealed}`);
  }

  console.log(`Calling Get Owned Tokes`);
  const tokenIds = await getOwnedTokens();
  console.log(`Get Owned Tokens: ${tokenIds}`);
  // const tokenDatas: IDougTokenDetails[] = getTokenDatas(tokenIds);
  // console.log(`tokenDatas: ${tokenDatas}`);
  const uris = await getTokenURIs({
    tokenIds,
    dougRevealed,
  });
  // console.log(`TokenInfos: ${tokenDatas}`);

  const balances = await getTokenBalances({ tokenIds, contract: bankContract });

  const metaDataRequests = createMetaDataRequests(tokenIds, uris, balances);
  const newMetaDatas = await (
    await Promise.all(metaDataRequests)
  ).filter((metaData) => metaData);

  if (!dougRevealed) return newMetaDatas;

  //console.log(`Meta Datas: ${JSON.stringify(newMetaDatas)}`);
  return orderDougs(newMetaDatas);
  //return newMetaDatas;
};

export const getTokenBalances = async (tokenIds) => {
  const bank = new ethers.Contract(bankContract, DougBankABI, provider);
  return Promise.all(
    tokenIds.map(async (tokenId) => {
      const rewardResult = await bank.tokenBalance(tokenId);
      return rewardResult;
    })
  );
};

export const getOwnedTokensBy = async (walletAddress) => {
  const dougGame = new ethers.Contract(dougContract, DougABI, provider);

  var dougs: any[] = [];
  console.log('gio getOwnedTokensBy');
  const events = await dougGame.queryFilter(
    dougGame.filters.Transfer(null, walletAddress)
  );
  console.log(`Events gathered: ${events.length}`);
  for (const e of events) {
    console.log(`Event: ${JSON.stringify(e)}`);
    dougs = [...dougs, e.args[2].toNumber()];
  }

  var ownedTokens: any[] = [];
  for (const doug of dougs) {
    try {
      console.log('gio ownerOf');
      const tokenOwner = await dougGame.ownerOf(doug);
      if (walletAddress.toLowerCase() === tokenOwner.toLowerCase()) {
        ownedTokens = [...ownedTokens, doug];
      }
    } catch {
      console.log(`Bad Doug Token: ${JSON.stringify(doug)}`);
    }
  }

  // console.log(`Owned Tokens: ${ownedTokens}`);
  return ownedTokens;
};

export const getTokenURIs = async ({ tokenIds, dougRevealed }) => {
  const tokenCacheKey = (tokenId: number) => {
    const revealed = dougRevealed ? 'doug' : 'doug_preveal';
    return `token_uri.${dougContract}.${revealed}.${tokenId}`;
  };
  const fromCache = new Map();
  for (const id of tokenIds) {
    const uri = localStorage.getItem(tokenCacheKey(id));
    if (uri) fromCache.set(id, uri);
  }

  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);

    const metaRequests = tokenIds.map((tokenId) => {
      const cached = fromCache.get(tokenId);
      // console.log(`TOKENURI tokenId: ${tokenId}`);
      if (cached) {
        return Promise.resolve(cached);
      }
      console.log('gio tokenURI');
      return dougGame.tokenURI(tokenId).then((uri) => {
        localStorage.setItem(tokenCacheKey(tokenId), uri);
        return uri;
      });
    });

    const ipfsUris = await Promise.all(metaRequests);

    return ipfsUris.map((ipfsUri: string) => {
      //console.log(`ipfsUri: ${ipfsUri}`);
      return ipfsUri.replace('ipfs://', '');
    });
  } catch (error) {
    console.error(`getTokenURIs failed: ${error}`);
    throw error;
  }
};

export const mint = async (amount, nonce, signature, code) => {
  if (!connectionInfo.provider) return false;

  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const signer = provider.getSigner();

  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, signer);

    const currentPrice = await getCurrentPrice(code === '');
    const value = currentPrice.mul(amount);
    const inviteMintValue = ethers.utils.parseEther('0.075');

    console.log(
      `Buying for ETH price: ${ethers.utils.formatEther(
        value
      )} wei price: ${value}`
    );

    const web3 = new Web3(connectionInfo.provider);
    console.log('gio getBalance');
    const balance = await web3.eth.getBalance(connectionInfo.wallet);
    console.log(`Balance: ${balance}`);

    console.log(`dougGame.mint(amount): (${amount})`);

    code !== '' ? console.log('gio mintWithInvite') : console.log('gio mint');
    const tx0 =
      code !== ''
        ? await dougGame.mintWithInvite(nonce, signature, {
            value: inviteMintValue,
          })
        : await dougGame.mint(amount, { value });

    localStorage.setItem(
      'tx0',
      JSON.stringify({
        hash: tx0.hash,
        type: 'mint',
        confirmations: tx0.confirmations,
      })
    );

    // await tx0.wait();

    // provider.once(tx0.hash, (tx) => {
    //   localStorage.removeItem('tx0');
    // });
    // console.log('MINT COMPLETE');
    return true;
  } catch (error) {
    console.error(`MINT Fail: ${error}`);
    throw error;
  }
};

export const mergeTokens = async (tokenA, tokenB) => {
  if (!connectionInfo.provider) return false;

  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const signer = provider.getSigner();

  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, signer);

    console.log('gio merge');
    const tx0 = await dougGame.merge(tokenA, tokenB);
    localStorage.setItem(
      'tx0',
      JSON.stringify({
        hash: tx0.hash,
        type: 'merge',
        confirmations: tx0.confirmations,
      })
    );
    // await tx0.wait();
    // localStorage.removeItem('tx0');
    // console.log('MERGE COMPLETE');
    return true;
  } catch (error) {
    throw error;
  }
};

export const makeTransaction = async (hash, removeHash) => {
  try {
    const result = await provider.once(hash, (tx) => {
      removeHash();
    });
    console.log(result);
    console.log('Transaction COMPLETE');
    return true;
  } catch (error) {
    localStorage.removeItem('tx0');
    console.error(`transaction Fail: ${error}`);
    throw error;
  }
};

export const collectAll = async (tokens) => {
  try {
    const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
    const signer = provider.getSigner();
    const bank = new ethers.Contract(bankContract, DougBankABI, signer);
    const tokenIds = tokens.map((token) => token.tokenId);
    console.log('gio transferTokenBalanceMany');
    const result = await bank.transferTokenBalanceMany(tokenIds);

    localStorage.setItem(
      'tx0',
      JSON.stringify({
        hash: result.hash,
        type: 'collectAll',
        confirmations: result.confirmations,
      })
    );

    return result;
  } catch (error) {
    console.error(`Collect all Fail: ${error}`);
    throw error;
  }
};

export const leaderboardRanks = async () => {
  console.log(`LeaderboardBank: ${bankContract}`);
  const bank = new ethers.Contract(bankContract, DougBankABI, provider);
  console.log(`Leaderboard _typeNext`);
  try {
    console.log('gio typeScores');
    const scores: number[] = await bank.typeScores();
    return { scores: scores };
  } catch (error) {
    console.log(`Leaderboard Error: ${JSON.stringify(error)}`);
  }
};

export const getTotalTokens = async () => {
  const customHttpProvider = new ethers.providers.JsonRpcProvider(
    CurrentJsonRpcProvider
  );

  console.log(`Doug Contract: ${dougContract}`);
  const dougGame = new ethers.Contract(
    dougContract,
    DougABI,
    customHttpProvider
  );

  const dougs = new Set<number>();

  console.log(`Do mints`);
  console.log('gio getTotalTokens mint');
  const mints = await dougGame.queryFilter(
    dougGame.filters.Transfer(ethers.constants.AddressZero, null)
  );
  console.log(`Do burns`);
  console.log('gio getTotalTokens burn');
  const burns = await dougGame.queryFilter(
    dougGame.filters.Transfer(null, ethers.constants.AddressZero)
  );

  console.log(`Do sort`);
  // sort by block no
  const events = [...mints, ...burns].sort(
    (a, b) => a.blockNumber - b.blockNumber
  );
  for (const evt of events) {
    const id = evt.args.tokenId.toNumber();
    const from = evt.args.from.toString(16);
    const to = evt.args.to.toString(16);

    if (to === ethers.constants.AddressZero) {
      dougs.delete(id);
    } else {
      dougs.add(id);
    }
  }
  const ownedTokens = Array.from(dougs);

  const dougInfos = Promise.all(
    ownedTokens
      .sort((tokenA, tokenB) => {
        return tokenA > tokenB ? 1 : -1;
      })
      .map(async (token) => {
        // const dougType = await dougGame.dougType(token);
        // const dougRank = await dougGame.dougRank(token);
        // console.log(`Existing Token: #${token} T${dougType} R${dougRank}`);
        console.log('gio tokenURI');
        const dougURI = await dougGame.tokenURI(token);
        console.log('gio dougType');
        const dougType = await dougGame.dougType(token);
        console.log('gio dougRank');
        const dougRank = await dougGame.dougRank(token);
        //console.log(`Existing Token: #${token} T${dougType} R${dougRank}`);
        return { dougToken: token, dougType: dougType, dougRank: dougRank };
      })
  );

  console.log(`return dougInfos;`);

  return dougInfos;
};

export const getWhiteListCountForAddress = async (wallet: string) => {
  if (!connectionInfo.provider) return false;

  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const signer = provider.getSigner();

  try {
    const dougGame = new ethers.Contract(soulboundContract, SoulBound, signer);
    console.log('gio getWhiteListCountForAddress');
    const whiteListCount = await dougGame.getWhiteListCountForAddress(wallet);

    // await tx0.wait();
    // localStorage.removeItem('tx0');
    // console.log('MERGE COMPLETE');
    return whiteListCount;
  } catch (error) {
    throw error;
  }
};

export const getWhitelistCount = async () => {
  if (!connectionInfo.provider) return false;

  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const signer = provider.getSigner();

  try {
    const dougGame = new ethers.Contract(soulboundContract, SoulBound, signer);
    const whiteListCount = await dougGame.getWhiteListCount();

    // await tx0.wait();
    // localStorage.removeItem('tx0');
    // console.log('MERGE COMPLETE');
    return whiteListCount;
  } catch (error) {
    throw error;
  }
};

export const whitelistSoulbound = async (wallet: string, count: number) => {
  if (!connectionInfo.provider) return false;

  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const signer = provider.getSigner();

  try {
    const dougGame = new ethers.Contract(soulboundContract, SoulBound, signer);
    console.log('gio whitelistAddress');
    const tx0 = await dougGame.whitelistAddress(wallet, count);
    localStorage.setItem(
      'tx0',
      JSON.stringify({
        hash: tx0.hash,
        type: 'mint_soulbound',
        confirmations: tx0.confirmations,
      })
    );
    // await tx0.wait();
    // localStorage.removeItem('tx0');
    // console.log('MERGE COMPLETE');
    return true;
  } catch (error) {
    throw error;
  }
};

export const whitelistAddresses = async (
  wallets: string[],
  count: number,
  allowedShareCodes: boolean
) => {
  if (!connectionInfo.provider) return false;
  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const signer = provider.getSigner();

  try {
    const dougGame = new ethers.Contract(soulboundContract, SoulBound, signer);
    const tx0 = await dougGame.whitelistAddresses(
      wallets,
      count,
      allowedShareCodes
    );
    localStorage.setItem(
      'tx0',
      JSON.stringify({
        hash: tx0.hash,
        type: 'whitelist',
        confirmations: tx0.confirmations,
      })
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const mintSoulbound = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
    const signer = provider.getSigner();

    const dougsoul = new ethers.Contract(soulboundContract, SoulBound, signer);
    console.log('gio priceSoulbound');
    const currentPrice = await priceSoulbound();
    const tx0 = await dougsoul.mint({ value: currentPrice });
    localStorage.setItem(
      'tx0',
      JSON.stringify({
        hash: tx0.hash,
        type: 'mint_soulbound',
        confirmations: tx0.confirmations,
      })
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const priceSoulbound = async () => {
  if (!connectionInfo.provider) return false;
  const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
  const signer = provider.getSigner();

  try {
    const dougsoul = new ethers.Contract(soulboundContract, SoulBound, signer);
    console.log('gio getCurrentPrice');
    const price = await dougsoul.getCurrentPrice();
    return price;
  } catch (error) {
    throw error;
  }
};

export const getOwnedSBTDougInformations = async () => {
  if (!connectionInfo.provider) return false;
  const tokenIds = await getOwnedSBTTokens();
  const uris = await getSBTTokenURIs(tokenIds);
  // const redeemed = await getTokenRedeemStatus(tokenIds);

  const metaDataRequests = createMetaDataSBTRequests(tokenIds, uris);
  const newMetaDatas = await (
    await Promise.all(metaDataRequests)
  ).filter((metaData) => metaData);
  return newMetaDatas;
};

export const getOwnedSBTTokens = async () => {
  try {
    const dougGame = new ethers.Contract(
      soulboundContract,
      SoulBound,
      provider
    );
    const dougs = new Set<number>();
    console.log('gio getOwnedSBTTokens mint');
    const mints = await dougGame.queryFilter(
      dougGame.filters.Transfer(null, connectionInfo.wallet)
    );
    console.log('gio getOwnedSBTTokens burn');
    const burns = await dougGame.queryFilter(
      dougGame.filters.Transfer(connectionInfo.wallet, null)
    );
    const events = [...mints, ...burns].sort(
      (a, b) => a.blockNumber - b.blockNumber
    );
    for (const evt of events) {
      const id = evt.args.tokenId.toNumber();
      const from = evt.args.from.toString(16);
      const to = evt.args.to.toString(16);
      if (to === connectionInfo.wallet) {
        dougs.add(id);
      } else {
        dougs.delete(id);
      }
    }
    const ownedTokens = Array.from(dougs);
    ownedTokens.sort((tokenA, tokenB) => {
      return tokenA > tokenB ? 1 : -1;
    });

    return ownedTokens;
  } catch (error) {
    console.log(`${error}`);
  }
};

export const getSBTTokenURIs = async (tokenIds) => {
  const tokenCacheKey = (tokenId: number) => {
    const type = 'SBTToken';
    return `token_uri.${soulboundContract}.${type}.${tokenId}`;
  };
  const fromCache = new Map();
  for (const id of tokenIds) {
    const uri = localStorage.getItem(tokenCacheKey(id));
    if (uri) fromCache.set(id, uri);
  }

  try {
    const dougGame = new ethers.Contract(
      soulboundContract,
      SoulBound,
      provider
    );
    const metaRequests = tokenIds.map((tokenId) => {
      const cached = fromCache.get(tokenId);
      if (cached) {
        return Promise.resolve(cached);
      }
      return dougGame.tokenURI(tokenId).then((uri) => {
        localStorage.setItem(tokenCacheKey(tokenId), uri);
        return uri;
      });
    });

    const ipfsUris = await Promise.all(metaRequests);

    return ipfsUris.map((ipfsUri: string) => {
      return ipfsUri.replace('ipfs://', '');
    });
  } catch (error) {
    console.error(`getSBTTokenURIs failed: ${error}`);
    throw error;
  }
};

export const getTokenRedeemStatus = async (tokenIds) => {
  const redeem = new ethers.Contract(soulboundContract, SoulBound, provider);
  return Promise.all(
    tokenIds.map(async (tokenId) => {
      console.log('gio isRedeemed');
      const redeemResult = await redeem.isRedeemed(tokenId);
      return redeemResult;
    })
  );
};

export const redeemAll = async (tokens) => {
  try {
    const provider = new ethers.providers.Web3Provider(connectionInfo.provider);
    const signer = provider.getSigner();
    const redeem = new ethers.Contract(
      soulRedeemerContract,
      SoulRedeemer.abi,
      signer
    );
    const tokenIds = tokens
      .filter((token) => {
        return !isRedeem(token);
      })
      .map((token) => token.tokenId);

    if (tokenIds.length === 0) {
      const _error = {
        code: 100,
        message: 'You dont have tokens to redeem',
      };
      throw _error;
    }

    const result = await redeem.redeemMany(tokenIds);
    tokenIds.forEach((tokenId) => {
      const type = 'SBTToken';
      localStorage.removeItem(
        `token_uri.${soulboundContract}.${type}.${tokenId}`
      );
    });

    localStorage.setItem(
      'tx0',
      JSON.stringify({
        hash: result.hash,
        type: 'redeemMany',
        confirmations: result.confirmations,
      })
    );

    return result;
  } catch (error) {
    console.error(`redeemAll Fail: ${error}`);
    throw error;
  }
};

export const getCurrenEpoch = async () => {
  try {
    const dougsoul = new ethers.Contract(
      soulboundContract,
      SoulBound,
      provider
    );
    const lastToken = await dougsoul.getLastTokenId();
    const epoch = await dougsoul.getEpoch(lastToken.toNumber() + 1);
    return epoch;
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const getLastTokenId = async () => {
  try {
    const dougsoul = new ethers.Contract(
      soulboundContract,
      SoulBound,
      provider
    );
    const lastToken = await dougsoul.getLastTokenId();
    return lastToken.toNumber();
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const getRedeemStart = async () => {
  const redeem = new ethers.Contract(
    soulRedeemerContract,
    SoulRedeemer.abi,
    provider
  );
  console.log('gio getRedeemStart');
  const RedeemStart: BigNumber = await redeem.getRedeemStart();
  return RedeemStart;
};

export const getSoldTokens = async () => {
  try {
    const dougGame = new ethers.Contract(dougContract, DougABI, provider);
    var dougs: any[] = [];
    const events = await dougGame.queryFilter(
      dougGame.filters.Transfer('0x0000000000000000000000000000000000000000')
    );

    for (const e of events) {
      if (e.args[1] !== soulRedeemerContract) {
        dougs = [...dougs, e.args[2].toNumber()];
      }
    }
    return dougs;
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const getEndAuction = () => {
  return parseInt(process.env.REACT_APP_PUBLIC_END_AUCTION);
};
