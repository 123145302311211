import React, { useRef, useState } from 'react';
import '../assets/styles/DougVisualizer.css';

function TimelineScrubber({
  blockIndex,
  numBlocks,
  timePerUnit,
  children,
  onTimeScrub,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const scrubContainer = useRef<HTMLDivElement>();

  const onDragDown = () => {
    setIsDragging(true);
  };

  const onDrag = (e) => {
    if (isDragging && scrubContainer.current) {
      // Get the bounding rectangle of target
      const rect = scrubContainer.current.getBoundingClientRect();

      // Mouse position
      const x = e.clientX - rect.left;
      // console.log(`X ${x}`);
      const newPercent = Math.max(Math.min(x / rect.width, 1.0), 0.0);
      const newTime = newPercent * numBlocks;
      onTimeScrub(newTime);
    }
  };

  const onDragUp = () => {
    setIsDragging(false);
  };
  const positionPct = Math.abs((blockIndex * 100.0) / numBlocks);

  return (
    <div
      className="header-visualizer"
      onMouseMove={onDrag}
      onMouseUp={onDragUp}
      onMouseLeave={onDragUp}
    >
      <div className="timeline">
        <div className="timeline-label">Mint Day</div>
        <div ref={scrubContainer} className="timeline-container">
          <div className="timeline-bg" />
          <div
            style={{
              left: positionPct + '%',
              transition: `left ${timePerUnit * 1.5}ms`,
              transitionTimingFunction: 'linear',
              backgroundColor: isDragging ? 'green' : 'white',
              cursor: 'pointer',
            }}
            className="timeline-handle"
            onMouseDown={onDragDown}
            onMouseUp={onDragUp}
          />
        </div>
        <div style={{ marginLeft: '30px' }} className="timeline-label">
          Now
        </div>
        <div
          style={{
            paddingLeft: '30px',
            position: 'relative',
            display: 'table-cell',
            verticalAlign: 'middle',
            margin: 'auto',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default TimelineScrubber;
