import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  Navigate,
} from 'react-router-dom';
import { routes } from './routes';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useEffect } from 'react';

import '../assets/styles/theme-2.css';
import '../assets/styles/mobile.css';
import Header from '../components/Header';
import Notification from '../components/Notification';
import logo from '../assets/theme-2/images/hanging_tags2.png';
import twitter from '../assets/theme-2/images/twitter.png';
import discord from '../assets/theme-2/images/discord.png';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import {
  connect,
  getCurrentPrice,
  getPromoStartedAt,
  getRedeemStart,
  getStartedAt,
  setContract,
} from '../helpers/web3Modal.service';

import { useAppSelector } from '../redux';
import { listenTransactions, setExpireDate } from '../redux/wallet';
import {
  setContracts,
  setDougReady,
  setPrice,
  setPromoStartedAt,
  setRedeemStart,
  setStartedAt,
} from '../redux/doug';
import { getContractAddress } from '../helpers/dougapi';
import { IContracts } from '../models/wallet';

import { hideNotification, showNotification } from '../redux/notification';
import { logout } from '../redux/actions/auth';

const Navigation = () => {
  const dispatch = useDispatch();
  const hasPendingTransactions = useAppSelector(
    (state) => state.wallet.transaction.hash !== ''
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const wallet = useSelector((state: RootStateOrAny) => state.wallet);
  const doug = useSelector((state: RootStateOrAny) => state.doug);

  useEffect(() => {
    if (wallet.account) {
      if (hasPendingTransactions) {
        dispatch(listenTransactions());
      }
    }
  }, [dispatch, wallet.account, hasPendingTransactions]);

  const contractHandler = async () => {
    const contracts = await getContractAddress();
    const _contracts: IContracts = {
      dougContract: contracts.doug,
      bankContract: contracts.bank,
      soulboundContract: contracts.soul,
      soulRedeemerContract: contracts.redeemer,
    };
    setContract(contracts);
    console.log(`set Contracts`);
    dispatch(setContracts(_contracts));
  };

  const priceHandler = async () => {
    try {
      const Price_ = await getCurrentPrice();
      console.log(`set Price`);
      dispatch(setPrice(Price_.toString()));
    } catch (error) {
      console.warn(error);
    }
  };

  const redeemStartHandler = async () => {
    const redeemStart = await getRedeemStart();
    console.log('set Redeem Start');
    dispatch(setRedeemStart(redeemStart.toString()));
  };

  const startedAtHandler = async () => {
    const startedAtResult = await getStartedAt();
    console.log('set Started at');
    dispatch(setStartedAt(startedAtResult.toString()));
  };

  const promoStartedAtHandler = async () => {
    const promoStartedAtResult = await getPromoStartedAt();
    console.log('set promo Started at');
    dispatch(setPromoStartedAt(promoStartedAtResult.toString()));
  };

  const accountHandler = async () => {
    try {
      let isLogout = false;
      const date = new Date();
      const accounts =
        (await window.ethereum.request({ method: 'eth_accounts' })) || false;
      if (wallet.account !== null && accounts.length > 0) {
        connect();
      }
      if (
        (wallet.account !== null && (!accounts || accounts.length === 0)) ||
        wallet.expireDate < date ||
        wallet.expireDate === 0
      ) {
        await dispatch(logout());
        isLogout = true;
        console.log('logout');
      }
      return isLogout;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const init = async () => {
    dispatch(showNotification('SYNCHRONIZING WALLET'));
    const isLogout = await accountHandler();
    if (isLogout) {
      await contractHandler();
      await priceHandler();
      await redeemStartHandler();
      await startedAtHandler();
      await promoStartedAtHandler();
      dispatch(setExpireDate());
    } else {
      if (doug.dougContract === '') await contractHandler();
      if (doug.price === '0') await priceHandler();
      if (doug.redeemStart === '0') await redeemStartHandler();
      if (doug.startedAt === '0') await startedAtHandler();
      if (doug.promoStartedAt === '0') await promoStartedAtHandler();
    }
    dispatch(setDougReady(true));
    dispatch(hideNotification());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <BrowserRouter>
      <div
        style={{ height: '100%', overflow: 'hidden' }}
        className="darkCamoBg"
      >
        <div
          id="div_nav"
          className="darkCamoBg"
          style={{ zIndex: 1500, position: 'fixed', width: '100%' }}
        >
          <div className="nav-container">
            <nav id="navbar" className="navbar">
              <a className="logo" href="https://commanderdoug.io/">
                <label className="logo-text-1">COMMANDER </label>
                <label className="logo-text-2"> DOUG</label>
                <Image src={logo} className="logo-image" />
              </a>

              <div className="menu-container">
                <IconButton
                  aria-label="Menu"
                  display={'none'}
                  className={'btn-collapse-menu'}
                  icon={<GiHamburgerMenu />}
                  variant="outline"
                  colorScheme={'whiteAlpha'}
                  onClick={isOpen ? onClose : onOpen}
                />
                <Drawer onClose={onClose} isOpen={isOpen}>
                  <DrawerOverlay />
                  <DrawerContent
                    zIndex={1000}
                    color={'white'}
                    bgColor={'black'}
                    width={'80%!important'}
                  >
                    <DrawerCloseButton />
                    <DrawerHeader>Commander Doug</DrawerHeader>
                    <DrawerBody>
                      <ul className="ul-mobile-menu">
                        {routes.map(({ to, name, title, subtitle, show }) =>
                          show ? (
                            <li key={to} className="li-nav">
                              <NavLink
                                style={{ display: 'block', width: '100%' }}
                                to={to}
                                onClick={onClose}
                              >
                                {name}
                              </NavLink>
                            </li>
                          ) : (
                            ''
                          )
                        )}
                      </ul>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
                <ul>
                  <li>
                    <a href="https://www.commanderdoug.io/white-paper">
                      White Paper
                    </a>
                  </li>
                  <li>
                    Play
                    <ul>
                      {routes.map(({ to, name, title, subtitle, show }) =>
                        show ? (
                          <li key={to} className="li-nav">
                            <NavLink
                              style={{ display: 'block', width: '100%' }}
                              to={to}
                            >
                              {name}
                            </NavLink>
                          </li>
                        ) : (
                          ''
                        )
                      )}
                    </ul>
                  </li>
                </ul>
              </div>
              <div>
                <div className="social-media">
                  <a
                    href="https://twitter.com/commanderdougio"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="img-fluid" src={twitter} alt="twitter" />
                  </a>
                  <a
                    href="https://discord.com/channels/950841046517047316/951549494435135518"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="img-fluid" src={discord} alt="discord" />
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <Header />
        <Notification />
        <Routes>
          {routes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default Navigation;
