import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from 'react-bootstrap';
import { Provider } from 'react-redux';
import './App.css';
import { store } from './redux';
import Navigation from './routes/Navigation';

function App() {
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    >
      <Provider store={store}>
        <div className="App background">
          <ChakraProvider>
            <Navigation />
          </ChakraProvider>
        </div>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
