import { createSlice } from '@reduxjs/toolkit';
import { IDougInformation } from '../models/dougMetadata';
import { logout } from './actions/auth';
import * as ac from './actions/doug';

export interface IWalletState {
  dougRevealed: boolean;
  dougContract: string;
  bankContract: string;
  soulboundContract: string;
  soulRedeemerContract: string;
  price: string;
  promoCode: string;
  redeemStart: string;
  ready: boolean;
  startedAt: string;
  promoStartedAt: string;
}

const initialState: IWalletState = {
  dougRevealed: false,
  dougContract: localStorage.getItem('dougContract') ?? '',
  bankContract: localStorage.getItem('bankContract') ?? '',
  soulboundContract: localStorage.getItem('soulboundContract') ?? '',
  soulRedeemerContract: localStorage.getItem('soulRedeemerContract') ?? '',
  price: localStorage.getItem('price') ?? '0',
  promoCode: localStorage.getItem('promoCode') ?? '',
  redeemStart: localStorage.getItem('redeemStart') ?? '0',
  startedAt: localStorage.getItem('startedAt') ?? '0',
  promoStartedAt: localStorage.getItem('promoStartedAt') ?? '0',
  ready: false,
};

const dougSlice = createSlice({
  name: 'doug',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.dougRevealed = false;
      state.dougContract = '';
      state.bankContract = '';
      state.soulboundContract = '';
      state.soulRedeemerContract = '';
      state.price = '0';
      state.promoCode = '';
    });
    builder.addCase(ac.setDougRevealed, (state, action) => {
      state.dougRevealed = action.payload ?? false;
    });
    builder.addCase(ac.setContracts, (state, action) => {
      state.dougContract = action.payload.dougContract;
      state.bankContract = action.payload.bankContract;
      state.soulboundContract = action.payload.soulboundContract;
      state.soulRedeemerContract = action.payload.soulRedeemerContract;
      localStorage.setItem('dougContract', state.dougContract);
      localStorage.setItem('bankContract', state.bankContract);
      localStorage.setItem('soulboundContract', state.soulboundContract);
      localStorage.setItem('soulRedeemerContract', state.soulRedeemerContract);
    });
    builder.addCase(ac.setPrice, (state, action) => {
      state.price = action.payload;
      localStorage.setItem('price', state.price);
    });
    builder.addCase(ac.setPromoCode, (state, action) => {
      state.promoCode = action.payload;
    });
    builder.addCase(ac.setDougReady, (state, action) => {
      state.ready = action.payload;
    });
    builder.addCase(ac.setRedeemStart, (state, action) => {
      state.redeemStart = action.payload;
      localStorage.setItem('redeemStart', state.redeemStart);
    });
    builder.addCase(ac.setStartedAt, (state, action) => {
      state.startedAt = action.payload;
      localStorage.setItem('startedAt', state.startedAt);
    });
    builder.addCase(ac.setPromoStartedAt, (state, action) => {
      state.promoStartedAt = action.payload;
      localStorage.setItem('promoStartedAt', state.promoStartedAt);
    });
  },
});

export * from '../redux/actions/doug';
export default dougSlice.reducer;
