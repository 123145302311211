import { IDougMetadata, IDougInformation } from '../models/dougMetadata';
import {
  ISoulboundInformation,
  ISoulboundMetadata,
} from '../models/soulboundMetadata';

const ipfsHostRoot = `https://doug.mypinata.cloud/ipfs/`;

export const swapIPFSWithHost = (uri) => {
  return uri.replace('ipfs://', ipfsHostRoot);
};

export const createMetaDataRequests = (
  tokenIds: number[],
  uris: string[],
  balances
) => {
  return uris.map((uri, idx) => {
    const getMetaData = async (uri) => {
      const res = await fetch(uri, {
        method: 'GET',
      });
      const metaData: IDougMetadata = await res.json();
      metaData.image = swapIPFSWithHost(metaData.image);
      return metaData;
    };

    var metaDataRequest = async () => {
      try {
        // TEMP SWAP TO GET RID OF WEIRD doug-revealed
        const metaData = await getMetaData(
          `https://doug.mypinata.cloud/ipfs/${uri}`
        );
        const tokenInfo: IDougInformation = {
          tokenId: tokenIds[idx],
          metadata: metaData,
          balance: balances[idx],
        };
        return tokenInfo;
      } catch (err) {
        console.log(err);
      }
    };

    return metaDataRequest();
  });
};

export const dougImageFromRankAndType = (
  type: number,
  rank: number,
  image: string
) => {
  return image.replace('_R' + rank, '_R' + (rank + 1));
  //return `./dougs/doug_${type}_R${rank}.png`;
};

export const errorMessageToFriendlyMessage = (error: any) => {
  console.warn(`Error: \n${JSON.stringify(error)}`);
  try {
    if (error.code === `INSUFFICIENT_FUNDS`) {
      return `INSUFFICIENT FUNDS`;
    }
    if (error.code === `ACTION_REJECTED`) {
      return `ACTION REJECTED BY USER`;
    }
    if (error.code === 4001) {
      return `User denied transaction signature`;
    }
    if (error.code === 'INVALID_ARGUMENT') {
      let message = '';
      switch (error.reason) {
        case 'missing provider':
          message = 'You have to connect your wallet first';
          break;
        default:
          message = 'UNKNOWN ERROR';
      }
      return message;
    }
    if (error.code === 100) {
      return error.message;
    }
    if (error.error.code === -32603) {
      let message = '';
      switch (error.error.message) {
        case 'execution reverted: Doug: auction has not started':
          message = 'Doug: cannot mint until the auction has started';
          break;
        case 'execution reverted: Doug: invites are not open':
          message = 'Doug: cannot mint with invite until invites are open';
          break;
        case 'execution reverted: Doug: reserve mint is incomplete':
          message = 'Doug: cannot mint until the auction has started';
          break;
        case 'execution reverted: Doug: auction already started':
          message =
            'Doug: cannot mint with invite when the auction has started';
          break;
        case 'execution reverted: Caller is not owner or admin':
          message = 'You are not admin or owner';
          break;
        case 'execution reverted: not on whitelist':
          message = 'Your wallet is not on whitelist';
          break;

        default:
          message = 'UNKNOWN ERROR';
      }
      return message;
    }
    if (error.code === 'INVALID_CODE') {
      return 'INVALID CODE';
    }
  } catch (error) {
    return `UNKNOWN ERROR`;
  }
};

export const replaceZero = (text: string) => {
  const search = '0';
  const replaceWith = 'O';
  const result = text.split(search).join(replaceWith);
  return result;
};

export const makeNiceCountdown = (countDown: number) => {
  // calculate time left
  const oneMinute = 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  const countDownSeconds = countDown;
  let timeLeft = countDownSeconds;
  const days = Math.floor(countDownSeconds / oneDay);
  timeLeft = timeLeft - days * oneDay;
  const hours = Math.floor(timeLeft / oneHour);
  timeLeft = timeLeft - hours * oneHour;
  const minutes = Math.floor(timeLeft / oneMinute);
  timeLeft = timeLeft - minutes * oneMinute;
  const seconds = Math.floor(timeLeft);

  return [days, hours, minutes, seconds];
};

export const createMetaDataSBTRequests = (
  tokenIds: number[],
  uris: string[]
) => {
  return uris.map((uri, idx) => {
    const getMetaData = async (uri) => {
      const res = await fetch(uri, {
        method: 'GET',
      });
      const metaData: ISoulboundMetadata = await res.json();
      metaData.image = swapIPFSWithHost(metaData.image);
      return metaData;
    };

    var metaDataRequest = async () => {
      try {
        const metaData = await getMetaData(uri);
        const tokenInfo: ISoulboundInformation = {
          tokenId: tokenIds[idx],
          metadata: metaData,
        };
        return tokenInfo;
      } catch (err) {
        console.log(err);
      }
    };

    return metaDataRequest();
  });
};

export const isRedeem = (token: ISoulboundInformation) => {
  const attributeRedeemed = token.metadata.attributes.filter(function (t) {
    return t.trait_type === 'Redeemed';
  });
  if (attributeRedeemed.length > 0)
    return attributeRedeemed[0].value.toLocaleLowerCase() === 'yes';
  return false;
};
