export const Ranks = [
  { title: 'Private' },
  { title: 'Corporal' },
  { title: 'Sergeant' },
  { title: 'Lieutenant' },
  { title: 'Captain' },
  { title: 'General' },
  { title: 'Commander' },
];

export default Ranks;
