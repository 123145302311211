import ImagePlay from '../assets/images/visualizer/play.png';
import ImagePause from '../assets/images/visualizer/pause.png';
import ImageRestart from '../assets/images/visualizer/restart.png';
import { PlayState } from '../enums/PlayState';

const PlayButton = ({ playState, onPlay, onPause, onRestart }) => {
  switch (playState) {
    case PlayState.Paused:
      return (
        <div onClick={onPlay}>
          <img className="doug-time-control" src={ImagePlay} alt="play" />
        </div>
      );
    case PlayState.Play:
      return (
        <div onClick={onPause}>
          <img className="doug-time-control" src={ImagePause} alt="pause" />
        </div>
      );
    case PlayState.Finished:
      return (
        <div onClick={onRestart}>
          <img className="doug-time-control" src={ImageRestart} alt="restart" />
        </div>
      );
    case PlayState.Loading:
      return (
        <div className='ring-loader' />
      );
  }
};

export default PlayButton;
