import { createSlice } from '@reduxjs/toolkit';
import * as ac from './actions/notification';

export interface INotificationState {
  show: boolean;
  message: string;
  error: string;
  closeButton: boolean;
  callback?: any;
}

const initialState: INotificationState = {
  show: false,
  message: '',
  error: '',
  closeButton: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ac.setNotification, (state, action) => {
      state.show = action.payload.show;
      state.message = action.payload.message;
      state.error = action.payload.error;
      state.closeButton = action.payload.closeButton;
      state.callback = action.payload.callback;
    });
    builder.addCase(ac.hideNotification, (state, action) => {
      state.show = false;
    });
    builder.addCase(ac.showNotification, (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.closeButton = false;
    });
    builder.addCase(ac.showNotificationError, (state, action) => {
      state.message = action.payload.message;
      state.error = action.payload.error;
      state.show = true;
      state.closeButton = true;
    });
    builder.addCase(ac.showNotificationCloseButton, (state, action) => {
      state.message = action.payload;
      state.show = true;
      state.closeButton = true;
    });
  },
});

export * from '../redux/actions/notification';
export default notificationSlice.reducer;
