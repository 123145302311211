import { useEffect, useRef, useState } from 'react';
import TimelineScrubber from '../components/DougTimelineScrubber';
import DougVisualizerRace from '../components/DougVisualizeRace';
import PlayButton from '../components/PlayButton';
import type { DougDay } from '../models/Doug';
import '../assets/styles/DougVisualizer.css';
import { PlayState } from '../enums/PlayState';
import { DougHistory } from '../helpers/visualizer.utils';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { showNotificationCloseButton } from '../redux/notification';

const Visualizer = () => {
  const dispatch = useDispatch();
  const doug = useSelector((state: RootStateOrAny) => state.doug);
  const [dougHistory, setDougHistory] = useState<DougHistory | null>(null);
  const [dougDay, setDougDay] = useState<DougDay | null>(null);
  const [message, setMessage] = useState('');
  const [dougHistoryLength, setDougHistoryLength] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playState, setPlayState] = useState(PlayState.Paused);
  const timer = useRef(null);
  const timePerUnit = 200;
  const slideTime = 500;

  useEffect(() => {
    const loadLeaderboard = async () => {
      const history = await DougHistory.load();
      setDougHistoryLength(history.length);

      if (doug.dougRevealed) {
        if (history.first) {
          setCurrentTime(history.length);
          const iterator = await history.startFrom(history.length - 1);
          const next = iterator.next();
          setDougDay(next.value);
          setPlayState(PlayState.Finished);
        }
        setDougHistory(history);
      } else {
        setMessage(`DOUG HASN'T BEEN REVEALED YET, CHECK BACK LATER`);
      }
    };
    loadLeaderboard();
  }, []);

  const stopTimer = () => {
    if (timer.current !== null) {
      clearInterval(timer.current);
    }
  };

  const onPlay = async () => {
    if (doug.dougRevealed) {
      setPlayState(PlayState.Loading);
      const iterator = await dougHistory.startFrom(currentTime);
      setPlayState(PlayState.Play);
      timer.current = setInterval(() => {
        const next = iterator.next();
        if (next.done) {
          setPlayState(PlayState.Finished);
          stopTimer();
          return;
        }
        setDougDay(next.value);
        setCurrentTime((i) => {
          console.log(i + 1, '/', dougHistoryLength);
          return i + 1;
        });
      }, timePerUnit);
    } else
      dispatch(
        showNotificationCloseButton(
          "DOUG HASN'T BEEN REVEALED YET, CHECK BACK LATER"
        )
      );
  };

  const onPause = () => {
    setPlayState(PlayState.Paused);
    stopTimer();
  };

  const onRestart = () => {
    setCurrentTime(0);
    setDougDay(dougHistory?.first);
    setPlayState(PlayState.Paused);
  };

  const onTimeScrub = async (newTime: number) => {
    if (newTime === currentTime) return;
    stopTimer();
    console.warn(`new time`, newTime);
    const iterator = await dougHistory.startFrom(Math.round(newTime - 1));
    const next = iterator.next();
    setDougDay(Math.round(newTime - 1) === 0 ? dougHistory?.first : next.value);
    setCurrentTime(newTime);
    setPlayState(PlayState.Paused);
  };

  return (
    <div>
      <TimelineScrubber
        blockIndex={currentTime}
        numBlocks={dougHistoryLength}
        timePerUnit={timePerUnit}
        onTimeScrub={onTimeScrub}
      >
        <PlayButton
          playState={playState}
          onPlay={onPlay}
          onPause={onPause}
          onRestart={onRestart}
        />
      </TimelineScrubber>
      <div className="visualizerBg">
        <div className="visualizer-container" style={{ display: 'flex' }}>
          <div className="mintbg2 visualizer-box">
            {dougDay && (
              <DougVisualizerRace dougs={dougDay} timePerUnit={slideTime} />
            )}
            {message.length > 0 && (
              <div className="div-mint-text-2 mint-text-6">{message}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visualizer;
