import { useEffect, useState } from 'react';
import {
  setContract,
  mint,
  getStartedAt,
  getDougsRemaining,
  getPromoStartedAt,
  GetMinPrice,
  getSoldTokens,
  getEndAuction,
} from '../helpers/web3Modal.service';
import Minting from '../components/Minting';
import Loading from '../components/Loading';
import {
  Button,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { preMint } from '../helpers/dougapi';
import '../assets/styles/mint.css';
import mint_box from '../assets/theme-2/images/mint_box_3.png';
import mint_diamond from '../assets/theme-2/images/diamond.png';
import mint_doug_1 from '../assets/theme-2/images/mint_doug_1.png';
import mint_doug_2 from '../assets/theme-2/images/mint_doug_2.png';
import mint_doug_3 from '../assets/theme-2/images/mint_doug_3.png';
import mint_doug_5 from '../assets/theme-2/images/mint_doug_5.png';
import mint_doug_10 from '../assets/theme-2/images/mint_doug_10.png';
import dog_tag_3 from '../assets/theme-2/images/dog_tag_3_3.png';
import dog_tag_3_2 from '../assets/theme-2/images/dog_tag_3_2.png';
import callout from '../assets/theme-2/images/callout.png';
import { errorMessageToFriendlyMessage, replaceZero } from '../helpers/utils';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { BigNumber, utils } from 'ethers';
import MintCountDown from '../components/MintCountDown';
import { AuctionState } from '../enums/AuctionState';
import ReCAPTCHA from 'react-google-recaptcha';

import ConnectButtonMobile from '../components/ConnectButtonMobile';
import {
  showNotification,
  showNotificationCloseButton,
  showNotificationError,
} from '../redux/notification';
import { setSubtitle } from '../redux/header';
import { setPromoCode } from '../redux/doug';
import { setTx } from '../redux/wallet';

const RECAPTCHA_KEY = '6LdfyVYhAAAAAO04D2TRpHi5H1gWAOJkeKkufjMr';

const Mint = () => {
  const state = useSelector((state: RootStateOrAny) => state);
  const wallet = useSelector((state: RootStateOrAny) => state.wallet);
  const doug = useSelector((state: RootStateOrAny) => state.doug);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [promoCode_, setPromoCode_] = useState(state.doug.promoCode);
  const [captcha, setCaptcha] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [startedAt, setStartedAt] = useState(BigNumber.from('-1'));
  const [promoStartedAt, setPromoStartedAt] = useState(BigNumber.from('-1'));
  const [auctionState, setAuctionState] = useState(AuctionState.Unknown);
  const [dougsRemaining, setDougsRemaining] = useState(BigNumber.from('0'));
  const [soldDougs, setSoldDougs] = useState(0);
  const [endAuction, SetEndAuction] = useState(0);

  const mintElements = [
    { qty: 1, coin: 'ETH', image: mint_doug_1, enable: true },
    { qty: 2, coin: 'ETH', image: mint_doug_2, enable: true },
    { qty: 3, coin: 'ETH', image: mint_doug_3, enable: true },
    { qty: 5, coin: 'ETH', image: mint_doug_5, enable: true },
    { qty: 10, coin: 'ETH', image: mint_doug_10, enable: false },
  ];
  const [minPriceReached, setMinPriceReached] = useState(false);

  const tryMint = async ({ amount }) => {
    try {
      //setShowLoading(true);
      dispatch(showNotification('MINT TRANSACTION PENDING'));

      let nonce = null;
      let signature = null;
      if (promoCode_ !== '') {
        const preMintData = {
          address: wallet.account,
          invite: promoCode_,
          captcha,
        };
        console.log(`Connected Wallet: ${wallet.account}`);
        const preMintResult = await preMint(preMintData);
        if (preMintResult.error != null) {
          if (preMintResult.error === 'Invalid invite') {
            let error = {
              error: new Error(preMintResult.error),
              code: 'INVALID_CODE',
            };
            throw error;
          }
        }
        console.log(`preMintResult: ${JSON.stringify(preMintResult)}`);
        nonce = preMintResult.nonce;
        signature = preMintResult.signature;
      }

      await mint(amount, nonce, signature, promoCode_);
      dispatch(setTx(JSON.parse(localStorage.getItem('tx0'))));
    } catch (error) {
      dispatch(
        showNotificationError({
          message: 'MINT FAILED',
          error: errorMessageToFriendlyMessage(error),
        })
      );
    }
  };

  const handleMintClick = (amount: number, disabled: boolean) => {
    if (disabled) {
      if (auctionState === AuctionState.Unknown) return;
      dispatch(
        showNotificationError({
          message: 'EARLY ACCESS ONLY',
          error: 'CLICK EARLY ACCESS BUTTON BELOW',
        })
      );
    } else {
      console.log(amount);
      tryMint({ amount });
    }
  };

  const tryGetStartedAt = async () => {
    try {
      setStartedAt(BigNumber.from(doug.startedAt));
      setPromoStartedAt(BigNumber.from(doug.promoStartedAt));

      const utcNow = Math.trunc(new Date().getTime() / 1000);
      if (utcNow < parseInt(doug.promoStartedAt)) {
        setAuctionState(AuctionState.NotStarted);
      } else if (utcNow < parseInt(doug.startedAt)) {
        setAuctionState(AuctionState.InviteOnly);
      } else {
        setAuctionState(AuctionState.DutchAuction);
      }
    } catch (error) {
      console.warn(`Failed tryGetStartedAt: ${error}`);
    }
  };

  const showEndAuction = () => {
    const utcNow = Math.trunc(new Date().getTime() / 1000);
    if (utcNow < endAuction) return true;
    else return false;
  };

  const TryGetDougsRemaining = async () => {
    try {
      const dougsRemaining = await getDougsRemaining();
      setDougsRemaining(dougsRemaining);
    } catch (error) {
      console.warn(`Failed TryGetDougsRemaining: ${error}`);
    }
  };

  const calculateSubtitle = () => {
    let subtitle = '';
    let subtitleOrange = '';
    let subtitleComplement = '';
    switch (auctionState) {
      case AuctionState.Unknown:
      case AuctionState.NotStarted:
      case AuctionState.InviteOnly:
        subtitle = 'REVERSE AUCTION / EARLY ACCESS';
        break;
      case AuctionState.DutchAuction:
        subtitle = 'REVERSE AUCTION:';
        subtitleOrange = 'PRICE DROPS EVERY 3O MINS';
        break;
    }
    if (
      state.header.subtitle !== subtitle ||
      state.header.subtitleOrange !== subtitleOrange ||
      state.header.subtitleComplement !== subtitleComplement
    )
      dispatch(
        setSubtitle({
          name: 'Mint',
          subtitle,
          subtitleOrange,
          subtitleComplement,
        })
      );
  };

  const init = async () => {
    TryGetDougsRemaining();
    tryGetStartedAt();
    verifyMinPrice();
    calculateSubtitle();
    const _endAution = getEndAuction();
    SetEndAuction(_endAution);
    const _soldDougs = await getSoldTokens();
    if (_soldDougs) setSoldDougs(_soldDougs.length);
  };

  useEffect(() => {
    if (doug.ready) init();
  }, [doug.ready]);

  const handlePromoClick = () => {
    switch (auctionState) {
      case AuctionState.Unknown:
        break;
      case AuctionState.NotStarted:
        {
          const startPromoTime = new Date(promoStartedAt.toNumber() * 1000);
          const stringPromoStartTime = replaceZero(
            startPromoTime.toUTCString()
          );
          console.log(`PROMO: ${stringPromoStartTime}`);
          dispatch(
            showNotificationError({
              message: 'PRIORITY LIST OPENS',
              error: stringPromoStartTime,
            })
          );
        }
        break;
      case AuctionState.InviteOnly:
        {
          onOpen();
        }
        break;
      case AuctionState.DutchAuction:
        {
          dispatch(showNotificationCloseButton('EARLY ACCESS PERIOD OVER'));
        }
        break;
    }
  };

  const handleSendPromoClick = () => {
    dispatch(setPromoCode(promoCode_));
    tryMint({ amount: 1 });
    onClose();
    dispatch(setPromoCode(''));
    setPromoCode_(state.promoCode);
  };

  const calculateInviteOnlyState = () => {
    //1 before started at is ready
    //2 after started at is ready
    return startedAt.toNumber() >= 0 ? 2 : 1;
  };

  const auctionMessage = () => {
    if (minPriceReached) {
      return { label1: '', label2: 'REVERSE AUCTION: ENDED' };
    }
    switch (auctionState) {
      case AuctionState.Unknown:
        return {
          label1: '',
          label2: '',
          largerText: 'LOADING',
          pulseMintButton: false,
          pulsePriorityButton: false,
          firstPrice: '____',
          remain: '____',
        };
      case AuctionState.NotStarted:
        return {
          label1: 'MINTING NOT ',
          label2: 'YET ENABLED',
          largerText: 'OPENING SOON',
          pulseMintButton: false,
          pulsePriorityButton: false,
          firstPrice: '0.075',
          remain: Math.round(dougsRemaining.toNumber()),
        };
      case AuctionState.InviteOnly:
        let response;
        switch (calculateInviteOnlyState()) {
          case 1:
            response = {
              label1: 'EARLY ACCESS ONLY',
              label2: '',
              largerText: 'EARLY ACCESS',
              pulseMintButton: false,
              pulsePriorityButton: true,
              firstPrice: '0.075',
              remain: Math.round(dougsRemaining.toNumber()),
            };
            break;
          case 2:
            response = {
              label1: 'REVERSE AUCTION STARTS IN',
              label2: '',
              largerText: false,
              pulseMintButton: false,
              pulsePriorityButton: true,
              firstPrice: '0.075',
              remain: Math.round(dougsRemaining.toNumber()),
            };
            break;
        }
        return response;
      case AuctionState.DutchAuction:
        return {
          label1: 'NEXT PRICE DROP IN',
          label2: '',
          largerText: false,
          pulseMintButton: true,
          pulsePriorityButton: false,
          firstPrice: '',
          remain: Math.round(dougsRemaining.toNumber()),
        };
    }
  };

  const mainMessage = () => {
    if (minPriceReached) return parseFloat(state.doug.price).toFixed(3);
    if (
      [AuctionState.NotStarted, AuctionState.InviteOnly].includes(auctionState)
    )
      return 'NOT STARTED';

    return false;
  };

  const verifyMinPrice = async () => {
    try {
      const minPrice = await GetMinPrice();
      if (minPrice === BigNumber.from(state.doug.price)) {
        setMinPriceReached(true);
      }
    } catch (error) {
      console.warn(`Failed verifyMinPrice: ${error}`);
    }
  };

  return (
    <div className="MainBg">
      <div className="mintdoug-container">
        <Loading show={showLoading} />
        <div className="mintbg2">
          <div className="mint-right-box">
            <Image className="image-box" src={mint_box} />
            <div className="mint-text-box">
              <div className="mint-text-box-1">BOX</div>
              <div className="mint-text-box-1">NOT</div>
              <div className="mint-text-box-2">INCLUDED!</div>
            </div>
            <Image src={callout} className="image-callout" />
          </div>
          <div className="mint-left-box">
            <div className="mint-container">
              <div className="mint-line-side">
                <div className="mint-line" style={{ marginLeft: '25px' }}></div>
              </div>
              <div className="div-mint-text-1">
                <label className="display-inline">
                  {auctionMessage().label1}
                </label>{' '}
                <label className="mint-text-1 display-inline">
                  {auctionMessage().label2}
                </label>
              </div>
              <div className="mint-line-side">
                <div className="mint-line"></div>
              </div>
            </div>
            <div className="mint-container2">
              <div className="div-mint-text-2 width-30">
                <label className="mint-text-2">CURRENT PRICE</label>
                <div className="mint-text-3">
                  <Image
                    h="30px"
                    display="inline-block"
                    pr="5px"
                    src={mint_diamond}
                  />
                  {auctionState === AuctionState.DutchAuction ? (
                    <span>
                      {parseFloat(state.doug.price).toFixed(3)}
                      <span className="span-title">
                        {Number(state.doug.price).toFixed(7)}
                      </span>
                    </span>
                  ) : (
                    <span>{auctionMessage().firstPrice}</span>
                  )}
                </div>
              </div>
              <div className="div-mint-text-2 mint-text-4">
                {!auctionMessage().largerText ? (
                  <MintCountDown
                    auctionState={auctionState}
                    startTime={Math.round(startedAt.toNumber())}
                    showTextDate={false}
                  />
                ) : (
                  auctionMessage().largerText
                )}
              </div>
              <div className="div-mint-text-2 width-30">
                <label className="mint-text-2">REMAIN</label>
                <div className="mint-text-3">{auctionMessage().remain}</div>
              </div>
            </div>
            <div className="mint-line" style={{ marginLeft: '25px' }}></div>
            <div className="mint-container">
              {mintElements.map((mint, i) => (
                <Minting
                  key={`minting_${i}`}
                  qty={mint.qty}
                  price={
                    auctionState === AuctionState.DutchAuction
                      ? parseFloat(utils.formatEther(state.doug.price)) *
                        mint.qty
                      : isNaN(parseFloat(auctionMessage().firstPrice))
                      ? auctionMessage().firstPrice
                      : parseFloat(auctionMessage().firstPrice) * mint.qty
                  }
                  coin={mint.coin}
                  image={mint.image}
                  enable={mint.enable}
                  handleMintClick={() =>
                    handleMintClick(
                      mint.qty,
                      !mint.enable || !auctionMessage().pulseMintButton
                    )
                  }
                  messageOptions={auctionMessage()}
                />
              ))}
            </div>
            <div className="mint-line" style={{ marginBottom: '5px' }}></div>
            <div>
              <Button
                className={
                  'btn-early-access ' +
                  (auctionMessage().pulsePriorityButton && 'btn-pulse') +
                  ' ' +
                  (!auctionMessage().pulsePriorityButton && 'btn-disabled')
                }
                onClick={handlePromoClick}
              >
                <label>EARLY ACCESS</label>
                <Image
                  src={
                    auctionMessage().pulsePriorityButton
                      ? dog_tag_3
                      : dog_tag_3_2
                  }
                />
              </Button>
            </div>
          </div>
          <div className="mint-left-box-mobile">
            <div className="mint-container">
              <div className="mint-line-side">
                <div className="mint-line"></div>
              </div>
              <div className="div-mint-text-1">
                <label className="display-inline">
                  {auctionMessage().label1}
                </label>{' '}
                <label className="mint-text-1 display-inline">
                  {auctionMessage().label2}
                </label>
              </div>
              <div className="mint-line-side">
                <div className="mint-line"></div>
              </div>
            </div>
            <div className="div-mint-text-2 mint-text-4">
              {!auctionMessage().largerText ? (
                <MintCountDown
                  auctionState={auctionState}
                  startTime={Math.round(startedAt.toNumber())}
                  showTextDate={false}
                />
              ) : (
                auctionMessage().largerText
              )}
            </div>
            <div className="mint-line"> </div>
            <div className="mint-soulbound-mobile-container">
              <div className="mint-mobile-text-side">
                <p className="soulbound-mobile-text">SOLD</p>
                <div className="mint-mobile-number">{soldDougs}</div>
              </div>

              <div className="mint-mobile-text-side">
                <p className="soulbound-mobile-text">REMAIN</p>
                <div className="mint-mobile-number">
                  {auctionMessage().remain}
                </div>
              </div>
            </div>

            <div className="mint-line"></div>
            <ConnectButtonMobile />
          </div>
        </div>
        <div className="mint-footer">
          <div className="mint-line"></div>
          <div className="mint-container">
            {mintElements.map((mint, i) => (
              <Minting
                key={`mobile_minting_${i}`}
                qty={mint.qty}
                price={
                  auctionState === AuctionState.DutchAuction
                    ? parseFloat(utils.formatEther(state.doug.price)) * mint.qty
                    : isNaN(parseFloat(auctionMessage().firstPrice))
                    ? auctionMessage().firstPrice
                    : parseFloat(auctionMessage().firstPrice) * mint.qty
                }
                coin={mint.coin}
                image={mint.image}
                enable={mint.enable}
                handleMintClick={() =>
                  handleMintClick(
                    mint.qty,
                    !mint.enable || !auctionMessage().pulseMintButton
                  )
                }
                messageOptions={auctionMessage()}
              />
            ))}
          </div>
          <div className="mint-line"></div>
          <div className="mint-mobile-container">
            <div className="mint-mobile-text-side">
              <p className="mint-mobile-text">
                CURRENT PRICE PER DOUG{' '}
                {auctionState === AuctionState.DutchAuction ? (
                  <span>
                    {replaceZero(parseFloat(state.doug.price).toFixed(3))}
                    <span className="span-title">
                      {replaceZero(
                        Number(utils.formatEther(state.doug.price)).toFixed(7)
                      )}
                    </span>
                  </span>
                ) : (
                  <span>{replaceZero(auctionMessage().firstPrice)}</span>
                )}{' '}
                ETH
              </p>
            </div>
            {showEndAuction() ? (
              <div className="mint-mobile-text-side">
                <p className="mint-mobile-text">
                  AUCTION ENDS IN{' '}
                  <MintCountDown
                    auctionState={auctionState}
                    startTime={endAuction}
                    showTextDate={true}
                  />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal
        id="mint-modal"
        size="lg"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInRight"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent backgroundColor="gray.900">
          <ModalBody
            p="10px"
            bgColor="#a19f9f"
            borderRadius="5px"
            textAlign="center"
          >
            <div id="priority-modal">
              <Button
                backgroundColor="gray.600"
                color="white"
                onClick={onClose}
                bgColor="#ca4609"
                width="75px"
                fontFamily="font-Airborne"
                zIndex="20"
                ml="10px"
                className="priority-close"
              >
                Close
              </Button>
              <label className="mint-text-3 whiteTc">
                <p>ENTER</p>
                <p>PRIORITY CODE</p>
              </label>
              <input
                size={10}
                defaultValue={promoCode_}
                onChange={(e) => setPromoCode_(e.target.value)}
                type="text"
                style={{ fontSize: '40px', margin: 'auto' }}
              />
              <label className="mint-text-2 whiteTc">
                THIS ALLOWS YOU TO PURCHASE AT O.O75 ETH
              </label>
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={setCaptcha}
              ></ReCAPTCHA>
              <div style={{ marginTop: '12px' }}>
                <Button
                  backgroundColor="gray.600"
                  color="white"
                  onClick={handleSendPromoClick}
                  bgColor="#ca4609"
                  fontFamily="font-Airborne"
                  zIndex="20"
                  fontSize="35px"
                  h="50px"
                  w="120px"
                >
                  Send
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Mint;
