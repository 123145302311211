import { useState, useRef, useEffect } from 'react';

import {
  Button,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import cammo_orange from '../assets/theme-2/images/cammo_orange.png';
import { Loading } from 'react-loading-dot/lib';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { hideNotification } from '../redux/notification';

const Notification = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const notification = useSelector(
    (state: RootStateOrAny) => state.notification
  );
  const cancelRef = useRef();
  const [close, setClose] = useState(false);
  const closeModal = () => {
    dispatch(hideNotification());
    setClose(false);
    onClose();
    if (notification.callback != null) notification.callback();
  };

  useEffect(() => {
    if (notification.show && !isOpen && !close) {
      onOpen();
    } else if (isOpen && close) {
      closeModal();
    } else {
      closeModal();
    }
  }, [notification.show, close, onClose]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setClose(true)}
        size="lg"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <div style={{ background: '#fff' }}>
            <AlertDialogContent
              bgImage={cammo_orange}
              background="rgb(255 128 60 / 80%)"
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                margin: '150px 0 0 0',
              }}
            >
              <AlertDialogHeader className="alert-dialog-header">
                {notification.message}
                {!notification.closeButton && (
                  <div className="dots">
                    <Loading background="#fff" duration="0.4s" />
                  </div>
                )}
              </AlertDialogHeader>
              <AlertDialogBody className="alert-dialog-body">
                {notification.error}
              </AlertDialogBody>
              <AlertDialogFooter m="auto">
                {notification.closeButton && (
                  <Button
                    className="btn-ok"
                    ref={cancelRef}
                    onClick={() => setClose(true)}
                  >
                    OK
                  </Button>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </div>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Notification;
