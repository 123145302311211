import { dougContract } from './web3Modal.service';
import { DougDay } from '../models/Doug';
import { dougDisplay } from '../models/doug_display';
import { DougToken, HistoryDecoder, HistoryStep } from './dougHistory';

export interface ITotalDougState {
  dougs: DougToken[];
  leaderboard: number[];
}

const historyLocation = process.env.REACT_APP_PUBLIC_VISUALIZER_URL;

export const openseaLink = (token) => {
  if (process.env.REACT_APP_PUBLIC_CHAIN_ID === '1') {
    return `https://opensea.io/assets/ethereum/${dougContract}/${token}`;
  } else {
    return `https://testnets.opensea.io/assets/goerli/${dougContract}/${token}`;
  }
};

const fetchHistory = async () => {
  const history = await fetch(historyLocation);
  const buffer = Buffer.from(await history.arrayBuffer());
  const decoder = new HistoryDecoder(buffer);
  const steps = decoder.decodeSteps();
  return Array.from(steps);
};

// convertDougStepsToDougHistory returns a generator of DougDay
//  that's consumed on every "tick". This had to be implemented this
//  way because towards the endgame computing 11k+ doug days in a single
//  pass freezes the browser for a few seconds
function* convertDougStepsToDougHistory(steps: HistoryStep[]) {
  let lastDougDay: DougDay = [];
  for (let i = 0; i < 100; i++) {
    const dp = dougDisplay[i];
    lastDougDay.push({
      image: dp.image,
      name: dp.name,
      ranks: [],
      tokens: [],
      score: 0,
      next_rank: i,
    });
  }
  for (const step of steps) {
    const dougDay: DougDay = [];
    const dougToken = step.dougs;

    for (let dougType = 0; dougType < 100; dougType++) {
      const dougTypeRanks = dougToken
        .filter((token) => token.type === dougType)
        .sort((a, b) => a.rank - b.rank);

      const dougRanks = dougTypeRanks.map((token) => token.rank);
      const dougIds = dougTypeRanks.map((token) => token.id);

      const ranks = [0, 1, 2, 3, 4, 5, 6];
      const dougRankCounts = ranks.map((rank) => {
        return dougTypeRanks.filter(
          (dougTypeRank) => rank === dougTypeRank.rank
        ).length;
      });

      const increments = [0, 1, 64, 2048, 32768, 262144, 1048576];

      const score = dougRankCounts.reduce(
        (prev, curr, idx) => prev + increments[idx] * curr,
        0
      );

      const dougDisplayInfo = dougDisplay[dougType];

      const doug = {
        image: dougDisplayInfo.image,
        name: dougDisplayInfo.name,
        ranks: dougRanks,
        tokens: dougIds,
        score: score,
        next_rank: step.leaderboard.indexOf(dougType),
      };

      dougDay.push(doug);
    }

    if (lastDougDay) {
      for (let dt = 0; dt < 100; dt++) {
        lastDougDay[dt].next_rank = step.leaderboard.indexOf(dt);
      }
      yield lastDougDay;
    }
    lastDougDay = dougDay;
  }
  yield lastDougDay;
}

type DougGenerator = Generator<DougDay, any, any>;

export class DougHistory {
  private readonly continuations = new Map<
    number,
    (g: DougGenerator) => void
  >();

  private processed = 0;

  private readonly cached: DougDay[] = [];

  public readonly first: DougDay;

  constructor(
    private readonly generator: DougGenerator,
    public readonly length: number
  ) {
    this.first = this.generator.next().value;
  }

  start() {
    const batchSize = 100;

    setImmediate(() => {
      let i = 0;
      for (; i < batchSize; i++) {
        const next = this.generator.next();
        if (next.done) break;
        this.cached.push(next.value);
      }
      this.processed += i;
      if (this.continuations.size > 0) {
        this.continuations.forEach((callback, index) => {
          if (index < this.processed) {
            callback(this.fromCache(index));
            this.continuations.delete(index);
          }
        });
      }
      this.start();
    });
  }

  *fromCache(start: number) {
    yield* this.cached.slice(start);
  }

  startFrom(index: number): Promise<DougGenerator> {
    return new Promise((resolve) => {
      if (index > this.processed) {
        this.continuations.set(index, resolve);
      } else {
        resolve(this.fromCache(index));
      }
    });
  }

  static async load(): Promise<DougHistory> {
    const history = await fetchHistory();
    const generator = convertDougStepsToDougHistory(history);
    const dougHistory = new DougHistory(generator, history.length);
    dougHistory.start();
    return dougHistory;
  }
}
