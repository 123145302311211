import { login, logout } from '../redux/actions/auth';
import { createSlice } from '@reduxjs/toolkit';
import { ITransaction } from '../models/transaction';
import * as ac from './actions/wallet';
import { IDougInformation } from '../models/dougMetadata';
import { ISoulboundInformation } from '../models/soulboundMetadata';

export interface IWalletState {
  account: string | null;
  ownTokens: IDougInformation[];
  ownSBTTokens: ISoulboundInformation[];
  transaction: ITransaction;
  expireDate: number;
}

const initialState: IWalletState = {
  account: localStorage.getItem('account') ?? null,
  ownTokens:
    (JSON.parse(localStorage.getItem('ownTokens')) as IDougInformation[]) ??
    ([] as IDougInformation[]),
  ownSBTTokens:
    (JSON.parse(
      localStorage.getItem('ownSBTTokens')
    ) as ISoulboundInformation[]) ?? ([] as ISoulboundInformation[]),
  transaction: (JSON.parse(localStorage.getItem('tx0')) as ITransaction) ?? {
    hash: '',
    type: '',
    confirmations: '',
  },
  expireDate: parseInt(localStorage.getItem('expireDate') ?? '0'),
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      localStorage.clear();
      state.account = null;
      state.expireDate = 0;
      console.log('removing account');
    });

    builder.addCase(login, (state, action) => {
      state.account = action.payload.account;
      state.ownTokens = action.payload.ownTokens;
      state.ownSBTTokens = action.payload.ownSBTTokens;
      localStorage.setItem('account', state.account);
      localStorage.setItem('ownTokens', JSON.stringify(state.ownTokens));
      localStorage.setItem('ownSBTTokens', JSON.stringify(state.ownSBTTokens));
    });

    builder.addCase(ac.setExpireDate, (state, action) => {
      const date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      state.expireDate = date.getTime();
      localStorage.setItem('expireDate', state.expireDate.toString());
    });

    builder.addCase(ac.setTx, (state, action) => {
      state.transaction = action.payload;
    });

    builder.addCase(ac.setOwntokens, (state, action) => {
      state.ownTokens = action.payload;
      localStorage.setItem('ownTokens', JSON.stringify(state.ownTokens));
    });
    builder.addCase(ac.setOwnSBTtokens, (state, action) => {
      state.ownSBTTokens = action.payload;
      localStorage.setItem('ownSBTTokens', JSON.stringify(state.ownSBTTokens));
    });
  },
});

export * from '../redux/actions/wallet';
export default walletSlice.reducer;
