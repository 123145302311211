import './Slider.css';
import { ISliderProps } from './ISliderProps';
import { Image } from '@chakra-ui/react';
import left_arrow_modal from '../../assets/theme-2/images/left_arrow_modal.png';
import right_arrow_modal from '../../assets/theme-2/images/right_arrow_modal.png';
import { replaceZero } from '../../helpers/utils';
import empty_doug from '../../assets/theme-2/images/empty_doug.png';
import { useState } from 'react';

const Slider = (props: ISliderProps) => {
  const [first, setFirst] = useState(props.items.length > 0 ? 1 : 0);
  const [last, setLast] = useState(props.items.length > 0 ? 3 : 0);
  const scrollPX = 630;
  const emptyItems = Array(3).fill({ image: empty_doug });
  const slideLeft = () => {
    const slider: HTMLElement = document.getElementById('slider')!;
    slider.scrollLeft = slider.scrollLeft - scrollPX;
    calculateShowingDougs(slider.scrollLeft);
  };
  const slideRight = () => {
    const slider: HTMLElement = document.getElementById('slider')!;
    let scrollMove = slider.scrollLeft + scrollPX;
    slider.scrollLeft = scrollMove;
    calculateShowingDougs(scrollMove);
  };

  const calculateShowingDougs = (scroll) => {
    let scrollUnit = scrollPX / 3;
    let scrollFirst = scroll / scrollUnit + 1;
    let scrollLast = scroll / scrollUnit + 3;
    if (props.items.length > 0 && scroll >= 0) {
      if (scrollLast < props.items.length) {
        setFirst(Math.round(scrollFirst));
        setLast(Math.round(scrollLast));
      } else {
        setFirst(props.items.length - 2);
        setLast(props.items.length);
      }
    }
  };

  const cardClass = 'slider-card';
  console.log(`Props: ${JSON.stringify(props.selected)}`);

  const preventDragHandler = (e) => {
    e.preventDefault();
    return false;
  };

  const dougNameDisplay = (slide) => {
    if (slide.metadata.doug)
      return replaceZero(
        slide.metadata.name.length > 30
          ? slide.metadata.name
              .split(':')
              .map((item) => item.trim())
              .join(':\n')
              .substring(0, 30) + '...'
          : slide.metadata.name
              .split(':')
              .map((item) => item.trim())
              .join(':\n')
      );

    return slide.metadata.name; //slide.metadata.name.replace("Pre Reveal ", "");
  };

  const dougTypeDisplay = (slide) => {
    if (slide.metadata.doug)
      return replaceZero(
        ('00' + slide.metadata.doug.type).substring(
          ('00' + slide.metadata.doug.type).length - 2
        )
      );

    return '';
  };

  return (
    <div id="main-slider-container">
      <div className="slider-icon left" onClick={slideLeft}>
        <Image src={left_arrow_modal} className="slider-arrow-image" />
      </div>
      <div
        id="slider"
        onMouseDown={preventDragHandler}
        onDragStart={preventDragHandler}
      >
        <div style={{ width: '70%' }}>
          <p style={{ position: 'absolute', top: '0', left: '16%' }}>
            <label className="slide-text-1 text-white">SELECT A DOUG </label>
            <label className="slide-text-1 text-orange">TO FUSE</label>
          </p>
          <p style={{ position: 'absolute', top: '0', left: '57%' }}>
            <label className="slide-text-4 text-white">SHOWING&nbsp;</label>
            <label className="slide-text-4 text-orange">
              {replaceZero(first.toString())} - {replaceZero(last.toString())}
            </label>
            <label className="slide-text-4 text-white">
              &nbsp;OF YOUR&nbsp;
            </label>
            <label className="slide-text-4 text-orange">
              {replaceZero(props.items.length.toString())}
            </label>
            <label className="slide-text-4 text-white">&nbsp;DOUGS</label>
          </p>
        </div>
        {props.items.length > 0 &&
          props.items.map((slide, index) => {
            return (
              <div
                className={cardClass}
                key={index}
                onClick={() => {
                  props.onItemSelected(
                    index,
                    slide.tokenId,
                    slide.metadata,
                    slide.balance
                  );
                }}
              >
                <div className="slider-card-image">
                  {slide.metadata.selected && (
                    <div className="slider-selected"></div>
                  )}
                  <Image
                    style={{ mixBlendMode: 'multiply' }}
                    src={slide.metadata.image}
                    maxWidth="100%"
                    maxHeight="100%"
                    m="auto"
                  />
                </div>
                <label
                  className="slide-text-2 text-white"
                  title={slide.metadata.name}
                >
                  {dougNameDisplay(slide)}
                </label>
              </div>
            );
          })}
        {props.items.length === 0 &&
          emptyItems.map((slide, index) => {
            return (
              <div className={cardClass} key={index}>
                <div className="slider-card-image">
                  <Image
                    src={slide.image}
                    maxWidth="100%"
                    maxHeight="100%"
                    m="auto"
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="slider-icon right" onClick={slideRight}>
        <Image src={right_arrow_modal} className="slider-arrow-image" />
      </div>
    </div>
  );
};
export default Slider;
