import { Button } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { routes } from '../routes/routes';
import MetaMaskOnboarding from '@metamask/onboarding';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { connectWallet } from '../redux/wallet';
import ConnectButtonMobile from './ConnectButtonMobile';

const Header = () => {
  const dispatch = useDispatch();
  const header = useSelector((state: RootStateOrAny) => state.header);
  const wallet = useSelector((state: RootStateOrAny) => state.wallet);
  let title = '';
  let subtitle = '';
  let subtitle_orange = '';
  let subtitle_complement = '';
  let subtitleTop = false;
  let mainHeaderStyle = {};
  let mobile_title_1;
  let mobile_title_2;
  let mobile_title_3;
  const location = useLocation();

  routes.forEach(function (route, idx) {
    if (route.path === location.pathname) {
      title = route.title;
      if (header.name === route.name) {
        subtitle = header.subtitle;
        subtitle_orange = header.subtitleOrange;
        subtitle_complement = header.subtitleComplement;
      } else {
        subtitle = route.subtitle;
        subtitle_orange = route.subtitle_orange;
        subtitle_complement = route.subtitle_complement;
      }
      mobile_title_1 = route.mobile_title_1;
      mobile_title_2 = route.mobile_title_2;
      mobile_title_3 = route.mobile_title_3;
      subtitleTop = route.subtitleTop;
    }
  });

  const connectHandler = async () => {
    console.log('connecting...');
    try {
      const onboarding = new MetaMaskOnboarding();

      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        if (wallet.account === null) {
          dispatch(connectWallet());
        } else console.log('already connected');
      } else {
        onboarding.startOnboarding();
      }
    } catch (error) {
      if (error) {
        window.open('https://metamask.io/download/', '_blank');
      }
    }
  };

  return (
    <div className="headerBg">
      <div className="header-titles">
        <div id="main-header" className="main-header">
          <div className="blank-div"></div>
          <div className="main-header-container">
            {subtitleTop && <h3 className="main-header-text-2">{subtitle}</h3>}

            <h1
              className={
                'main-header-text-1 ' +
                (mobile_title_1 !== undefined ? 'mint-text-mobile-hide' : '')
              }
            >
              {title}
            </h1>
            {mobile_title_1 !== undefined && (
              <div className="main-header-text-container">
                <h1 className="main-header-text-mobile-1">{mobile_title_1}</h1>
                <h1 className="main-header-text-mobile-2">{mobile_title_2}</h1>
                <h1 className="main-header-text-mobile-3">{mobile_title_3}</h1>
                <div className="btn-connect-mobile-container">
                  <ConnectButtonMobile />
                </div>
              </div>
            )}

            {!subtitleTop && (
              <h3>
                <span className="main-header-text-2">{subtitle}</span>{' '}
                <span className="main-header-text-3">{subtitle_orange}</span>{' '}
                <span className="main-header-text-2">
                  {subtitle_complement}
                </span>
              </h3>
            )}
          </div>
          <div className="blank-div">
            <Button
              className="btn-connect"
              // display="table-cell"
              // loadingText="Loading"
              // colorScheme="teal"
              // variant="outline"
              // spinnerPlacement="end"
              onClick={() => connectHandler()}
            >
              <label>{wallet.account ? 'CONNECTED' : 'CONNECT WALLET'}</label>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
