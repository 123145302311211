import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { IDougMetadata } from '../models/dougMetadata';
import Slider from '../components/Slider/Slider';

// DEBUG TESTING ONLY - COMMENT FOLLOWING 3 LINES OUT BEFORE SHIPPING
// import testTag from '../assets/theme-2/images/1_Private.jpg';
// import testRank1 from '../assets/theme-2/images/08_PandemicDoug_R1.png';
// import testRank2 from '../assets/theme-2/images/08_PandemicDoug_R2.png';

import dog_tag_4 from '../assets/theme-2/images/dog_tag_4.png';
import dog_tag_3 from '../assets/theme-2/images/dog_tag_3.png';
import dog_tag_3_2 from '../assets/theme-2/images/dog_tag_3_2.png';
import black_doug from '../assets/theme-2/images/doug_silhouette_black.png';
import hanging_tags from '../assets/theme-2/images/hanging_tags2.png';
import Ranks from '../enums/Ranks';
import gsap from 'gsap';
import doug_electro from '../assets/theme-2/images/doug_electro.png';
import electrocute_effect from '../assets/theme-2/images/Electrocute.gif';
import burning_anim from '../assets/theme-2/images/Burning.gif';
import { useDispatch } from 'react-redux';
import { showNotificationCloseButton } from '../redux/notification';
import Notification from './Notification';
import './Slider/Slider.css';

const Card = (props) => {
  const dispatch = useDispatch();
  const feederBox = useRef();
  const feederBoxFX = useRef();
  const mainBox = useRef();
  const dougMerged = useRef();
  const dougSkeleton = useRef();
  const effectElectro = useRef();
  const dougA = useRef();
  const dougB = useRef();
  const [showLoading, setShowLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const emptySelectedDougs: boolean[] = [];
  const [selectedDougs, setSelectedDougs] = useState(emptySelectedDougs);
  const [token, setToken] = useState(-1);
  const [pulseButton, setPulseButton] = useState('btn-fuse');
  const [fuseButtonText, setFuseButtonText] = useState('FUSE');
  const [fuseButtonImg, setFuseButtonImg] = useState(dog_tag_3_2);
  const [defaultDoug, setDefaultDoug] = useState(black_doug);
  const [nextRankText, setNextRankText] = useState('NEXT RANK');
  const [nextRankMergedText, setNextRankMergedText] = useState('');
  const [notification, setNotification] = useState({
    show: false,
    message: '',
    error: '',
    closeButton: false,
  });
  const openModal = () => {
    props.onClick();
    onOpen();
    clearCards();
  };

  const clearCards = () => {
    if (props.animate) {
      props.setAnimate(false);
      props.setDougTag(null);
      props.onRemove(1);
      props.onRemove(2);

      setNextRankMergedText('');
    }
  };

  const [reversed, setReversed] = useState(false);
  const el = useRef();
  const q = gsap.utils.selector(el);

  const tl = useRef<GSAPTimeline>();

  const animate = () => {
    tl.current = gsap
      .timeline()

      // reset the starting parameters for quicker iteration

      //.set(dougMerged.current, { opacity: 1 })
      .set(effectElectro.current, { opacity: 0, attr: { src: black_doug } }) // reset image so Gif will replay
      .set(dougSkeleton.current, { x: 0, y: -8, opacity: 0 })
      .set(dougA.current, {
        x: -200,
        y: -28,
        opacity: 0.5,
        attr: { src: props.dougA },
      })
      .set(dougB.current, {
        x: 200,
        y: -28,
        opacity: 1,
        attr: { src: props.dougB },
      })

      // DEBUG TESTING ONLY - COMMENT FOLLOWING 2 LINES OUT BEFORE SHIPPING
      //.set(dougA.current, { x: -200, y:-28, opacity: .5, attr: { src: testRank1 } })
      //.set(dougB.current, { x: 200, y:-28, opacity: 1, attr: { src: testRank1 } })

      // set off the flames
      .set(feederBoxFX.current, { attr: { src: black_doug } })
      .set(feederBoxFX.current, { attr: { src: burning_anim } })
      .set(feederBoxFX.current, { opacity: 1 })
      .to(feederBox.current, { opacity: 0, duration: 1, ease: 'power2.inOut' })
      .set(feederBox.current, { attr: { src: props.dougTag } }) // GIO NEED DOUGTAG HERE

      // DEBUG TESTING ONLY - COMMENT FOLLOWING 1 LINES OUT BEFORE SHIPPING
      //.set(feederBox.current, { attr: {src: testTag }})

      // Slide Dougs In
      .add('start')
      .to(
        feederBox.current,
        { opacity: 1, duration: 0.6, ease: 'none' },
        'start'
      )
      .to(
        dougA.current,
        { x: 358, duration: 3, ease: 'elastic.out(1, 0.75)' },
        'start'
      )
      .to(
        dougB.current,
        { x: -354, duration: 3, ease: 'elastic.out(1, 0.75)' },
        'start'
      )
      .to(dougMerged.current, { opacity: 0, duration: 3 }, 'start')
      .set(dougA.current, { opacity: 0 })

      // Cache in the new rank image ready for later
      .set(dougMerged.current, { zIndex: 3, y: -8, opacity: 0.1 })
      .set(dougMerged.current, { attr: { src: props.image } })

      // DEBUG TESTING ONLY - COMMENT FOLLOWING LINE OUT BEFORE SHIPPING
      //.set( dougMerged.current, { attr: { src: testRank2 } })

      // shake him
      .set(dougB.current, { x: -354 + 2, y: -28 - 3 })
      .add('start2')
      .to(
        dougB.current,
        { x: '-=4', yoyo: true, repeat: 50, duration: 0.04 },
        'start2'
      )
      .to(
        dougB.current,
        { y: '+=6', yoyo: true, repeat: 40, duration: 0.05 },
        'start2'
      )
      .set(dougB.current, { x: -354 - 0, y: -28 + 0 })

      // start the electric effect
      .set(effectElectro.current, { attr: { src: electrocute_effect } })
      .set(effectElectro.current, { opacity: 1 })
      // pause
      .to(dougB.current, { duration: 0.2 })

      // flash between old rank and skeleton
      .to(dougSkeleton.current, {
        opacity: 1,
        yoyo: true,
        repeat: 6,
        duration: 0.1,
        ease: 'none',
      })

      // Invert DougB
      .set(mainBox.current, { backgroundColor: '#ffffff' })
      .set(dougB.current, { opacity: 1, mixBlendMode: 'difference' })

      // flash between inverted old rank and skeleton
      .to(dougSkeleton.current, {
        opacity: 0,
        yoyo: true,
        repeat: 5,
        duration: 0.1,
        ease: 'none',
      })

      // Un-Invert DougB, and hide it
      .set(dougB.current, { opacity: 0, mixBlendMode: 'normal' })
      //Invert dougMerged, and reveal it
      .set(dougMerged.current, {
        zIndex: 45,
        opacity: 1,
        mixBlendMode: 'difference',
      })

      // flash between inverted new rank and skeleton
      .to(dougSkeleton.current, {
        opacity: 0,
        yoyo: true,
        repeat: 5,
        duration: 0.1,
        ease: 'none',
      })

      // Un-Invert dougMerged
      .set(dougMerged.current, { opacity: 1, mixBlendMode: 'normal' })
      .set(mainBox.current, { backgroundColor: '#b7b7b7' })

      // flash between new rank and skeleton
      .to(dougSkeleton.current, {
        opacity: 0,
        yoyo: true,
        repeat: 4,
        duration: 0.1,
        ease: 'none',
      })

      // Finish Up

      .to(
        { value: '' },
        {
          value: '',
          onUpdate: setNextRankText,
          onUpdateParams: [''],
        }
      )
      .to(
        { value: '' },
        {
          value: '',
          onUpdate: setFuseButtonText,
          onUpdateParams: ['CLEAR'],
        }
      );
    // setNextRankText('');
    // setFuseButtonText('CLEAR');
    setNextRankMergedText(props.rankMerged);
  };

  useEffect(() => {
    if (props.animate) {
      animate();
    } else {
      tl.current = gsap
        .timeline()
        .to(dougMerged.current, { attr: { src: black_doug } });
      setNextRankText('NEXT RANK');
      setDefaultDoug(black_doug);
      setNextRankText('NEXT RANK');
      setFuseButtonText('FUSE');
      setPulseButton('btn-fuse');
      setFuseButtonImg(dog_tag_3_2);
    }
    //tl.current.reversed(false);
  }, [props.animate]);

  useEffect(() => {
    if (props.dougA !== props.whiteDoug && props.dougB !== props.whiteDoug) {
      setPulseButton('btn-fuse btn-pulse');
      setFuseButtonImg(dog_tag_3);
    }
    //tl.current.reversed(false);
  }, [props.dougA, props.dougB]);

  const onDougClicked = (
    index,
    tokenId: number,
    metaData: IDougMetadata,
    balance: any
  ) => {
    if (metaData.selected) {
      dispatch(showNotificationCloseButton('THIS DOUG IS ALREADY SELECTED'));

      return;
    }

    console.log(`Token Clicked: ${index}`);
    onClose();

    const newSelectedDougs = selectedDougs.slice();
    newSelectedDougs[index] = !newSelectedDougs[index];
    if (newSelectedDougs[index]) {
      if (token < 0) {
        console.log(`setTokenA: ${tokenId}`);
        // Make sure we are revealed
        if (metaData.doug) {
          props.onItemSelected(
            props.slot,
            tokenId,
            metaData.doug.rank,
            metaData.doug.type,
            metaData.image,
            balance
          );
        } else {
          dispatch(
            showNotificationCloseButton('CANNOT FUSE DOUGS UNTIL UNBOXING DAY')
          );
        }
      }
    } else {
      if (token === tokenId) setToken(-1);
    }

    setSelectedDougs(newSelectedDougs);
  };
  let style = props.isResult
    ? { display: 'table', zIndex: '50' }
    : { display: 'table' };
  return (
    <Box style={style}>
      {!props.isResult && (
        <div className="fuse-card-lateral-container">
          <Box className="fuse-card-lateral">
            <Image
              className="fuse-card-lateral-image"
              ref={feederBox}
              src={props.image}
            />
            <Image
              className="fuse-card-lateral-image-hide"
              ref={feederBoxFX}
              src={burning_anim}
            />
          </Box>

          <Button className="btn-fuse-add btn-pulse" onClick={openModal}>
            <Image position="absolute" src={dog_tag_3} />
            <label>ADD</label>
          </Button>
          <Image
            className={
              'fuse-arrow-mobile ' +
              (props.slot === 1 ? 'arrow-mobile-left' : 'arrow-mobile-right')
            }
            src={props.arrowMobileImage}
          />
          <div className="fuse-card-lateral-tag-container">
            <Image className="fuse-card-lateral-tag" src={dog_tag_4} />
            <div className="fuse-card-lateral-tag-text-container">
              <label className="fuse-text-add-nft">
                {props.rank >= 0 ? Ranks[props.rank].title : 'ADD ORDINAL'}
              </label>
            </div>
            <div className="fuse-text fuse-text-rotate">RANK</div>
          </div>
        </div>
      )}
      {props.isResult && (
        <div className="fuse-card-central-container">
          <div className="fuse-footer-card-container-mobile">
            <Button
              className={pulseButton}
              onClick={() => {
                if (fuseButtonText === 'FUSE') {
                  props.onMerge();
                  setReversed(!reversed);
                } else {
                  clearCards();
                }
              }}
            >
              <Image position="absolute" src={fuseButtonImg} />
              <label>{fuseButtonText}</label>
            </Button>
            <p className="fuse-p">
            Fusing will sacrifice one Doug
              <br />
              to upgrade the other
            </p>
          </div>
          <Box className="fuse-card-central" ref={mainBox}>
            <Image ref={dougMerged} className="mergedDoug" src={black_doug} />
            <Image
              ref={dougSkeleton}
              className="mergedDoug2"
              src={doug_electro}
            />
            <Image
              ref={dougA}
              className="mergedDougLateral left"
              src={props.dougA}
            />
            <Image
              ref={dougB}
              className="mergedDougLateral right"
              src={props.dougB}
            />
            <Image ref={effectElectro} className="mergedDoug" />
          </Box>
          <div style={{ position: 'relative' }}>
            <Image className="fuse-card-central-tag" src={dog_tag_4} />
            <div className="fuse-text-next-rank">{nextRankText}</div>
            <div className="fuse-text-rank">{nextRankMergedText}</div>
            <Image className="fuse-hanging-tag" src={hanging_tags} />
          </div>

          <div className="fuse-footer-card-container">
            <Button
              className={pulseButton}
              onClick={() => {
                if (fuseButtonText === 'FUSE') {
                  props.onMerge();
                  setReversed(!reversed);
                } else {
                  clearCards();
                }
              }}
            >
              <Image position="absolute" src={fuseButtonImg} />
              <label>{fuseButtonText}</label>
            </Button>
            <p className="fuse-p">
            Fusing will sacrifice one Doug
              <br />
              to upgrade the other
            </p>
          </div>
        </div>
      )}

      <Modal
        id="doug-modal"
        size="lg"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInRight"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent backgroundColor="gray.900">
          <ModalBody p="10px" bgColor="#a19f9f" borderRadius="5px">
            <Slider
              items={props.metaDatas}
              selected={selectedDougs}
              onItemSelected={onDougClicked}
            />
            <Button className="slider-close-button" onClick={onClose}>
              Close
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Card;
